<template>
<div class='stat'>
  <div class='name'>{{name}}</div>
  <!-- {{stats[name]}} -->
  <div class='cont' tabIndex='-1'><div class='val all'>{{stats[name].all||0}}
  </div>
    <div class='hidden'>
      <template v-for='(e, k) in stats[name]' :key='k'>
        <template v-if="k !== 'all'">
          <div :class="`val ${k.replace(/ (.)/mg, (d, t) => t.toUpperCase())}`" :title='k'>
          {{e[0]||0}}
          </div>
        </template>
      </template>
    </div>
  </div>
</div>
</template>

<script>
//import {db} from '../../../db/mjs.js';
//import {stats} from '../../../db/stats.js';
export default {
name: 'tStat',
props: {
  statsV: Object,
  name: String
},
computed: {
  stats: {
    get() {
      return this.statsV
    },
    set(value) {
      this.$emit('update:statsV', value)
    }
  }
},
data(){
  return {
  }
},
setup(){
}
}
</script>

<style scoped>
</style>