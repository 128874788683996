<template>
<label @mousedown='onClick($event)' class='l-select'>{{label && name||''}}
  <div tabIndex='-1' :name='name' class='select scrollMid'>
    <div class='main'>
      <input disabled :value='picked||""' />
    </div>
    <div class='items'>
      <Uitem label='-' :name='name' value='-' :checked='true' @getItem='sendNull($event)' />
      <template v-for='i in items' :key='i.name'>
        <!-- <template v-if='i.type === "group"'> -->
          <Ugroup :name='i.name' :type='name' :items='i.db' @getPath='getPath($event)' />
        <!-- </template> -->
      </template>
    </div>
    <!-- <list :items='list' /> -->
  </div>
</label>
</template>

<script>
import Ugroup from './u-group.vue';
import Uitem from './u-item.vue';
export default {
name: 'UsSelect',
components: {
Ugroup,
Uitem
},
props: {
name: String,
label: Boolean,
mm: Boolean,
items: Object,
statsV: Object,
barV: Object,
savedV: Object
//picked: String
},
computed: {
stats: {
  get() {
    return this.statsV
  },
  set(value) {
    this.$emit('update:statsV', value)
  }
},
saved: {
  get() {
    return this.savedV
  },
  set(value) {
    this.$emit('update:savedV', value)
  }
},
bar: {
  get() {
    return this.barV
  },
  set(value) {
    this.$emit('update:barV', value)
  }
}
},
methods: {
  onClick(e){
    e.target.parentNode.click();
  },
  getPath(e){
    //console.log('path', e);

    if(e.type === 'hover'){
      this.setInfo(e, this.items);
    }else
    if(e.type === 'pick'){
      this.save(e, this.items);
      this.change(e, this.items);
    }
  },
  sendNull(e, item){
    //console.log('[SEND group]')
    e.data.push({group:item});
    this.getPath(e);
    //console.log('yoxi', e)
  },
  setInfo(e, file){
    const s = {
      name: e.data[0].item,
      index: e.data[0].index,
      group: e.data[1].group
    };
    const info = file[s.group].db[s.index];
    this.bar.title = info.name;
    this.bar.slot = info.slot;
    this.bar.type = info.type;
    this.bar.races = info.races;
    this.bar.description = info.description;
    this.bar.stats = info.stats

    console.log('INFO', info);
  },
  save(e){
    const s = {
      type: e.data[0].name,
      name: e.data[0].item,
      index: e.data[0].index,
      group: e.data[1].group
    };

    this.saved[s.type] = `${s.type}=${s.group},${s.name}`;

    //this.saved[s.type] = `${s.type}=${s.group ? `${s.group},${s.name}`:`${s.name}`}`;

    console.log('SAVE', this.saved);
  },
  change(e, file){
    //console.log('WIX', e);
    // if(!e.selectedIndex > 0) return;
    const s = {
      name: e.data[0].item,
      index: e.data[0].index,
      group: e.data[1].group
    };
    //console.log('CHANGE', s)
    this.picked = s.name;
    //console.log(this.db[s.group].db[s.index])
    //console.log(this.db[s.group][s.index]);

    s.group ? this.setter({file:file[s.group].db[s.index]}) : this.setter({name:s.name});
  },
  clear(slot){
    console.log('[CLEAR]', slot)
    //console.log('STATS', this.stats);
    for(let i in this.stats){
      //console.log(i, this.stats[i][slot])
      this.stats[i][slot] = [];
      //console.log(i+' '+slot, this.stats[i][slot])
    }
    this.getSum();
    //console.log('STATS after clear', this.stats)
  },
  getSum(){
    for(let s in this.stats){
      //console.log('stattt', s);
      this.stats[s].all = (() => {
        let stat = 0;
        for(let i in this.stats[s]){
          if(!this.stats[s][i][0]) continue;
          else
          if(this.stats[s][i][1] !== '%') stat += +this.stats[s][i][0];
          else
          stat *= +this.stats[s][i][0];
        }
        return stat;
      })()
      
    }
  },
  setter(o){
    //console.log('[Setter]', o.file);

    if(!o.file){
      this.clear(!this.mm ? this.name : this.name+' ММ');
      //for(let s in this.stats){
      //  this.stats[s][o.name] = '';
      //}
    }else{
      this.clear(!this.mm ? this.name : this.name+' ММ');

      for(let s in o.file.stats){
        //console.log(`CHECK s:${s}, e:${e}`);
        this.stats[s][!this.mm ? this.name : this.name+' ММ'] = o.file.stats[s];
      }

      this.getSum();
    }

    //console.log('[stats]', this.stats);
  }
},
data(){
return {
  picked: false
}
}
}
</script>

<style>
:is(.scrollLite, .scrollMid)::-webkit-scrollbar-thumb {
background-color: rgb(189 164 164);
}
:is(.scrollLite, .scrollMid)::-webkit-scrollbar-corner {
background-color: unset;
}
.scrollLite::-webkit-scrollbar {
width: 2px;
height: 2px;
}
.scrollMid::-webkit-scrollbar {
width: 4px;
height: 4px;
}

.l-select {
display: flex;
flex-direction: column;
gap: 1px 0;

.select {
  display: flex;
  flex-direction: column;
  gap: 0 0;
  padding: 1px 5px 1px 5px;
  width: max-content;
  position: relative;
  overflow: auto;
  max-height: 120px;
  border: 1px solid rgb(0 0 0);

  .main {
    display: flex;
    gap: 0 10px;

    &::after {
      display: block;
      content: '⬇';
    }

    input {
      width: 100%;
      border: unset;
      pointer-events: none;
    }
  }

  .items {
    display: none;
    flex-direction: column;
    gap: 5px 0;

    .item[type='radio'] {
      margin: 0;
      padding: 0;
      left: 0;
      position: absolute;
      width: 100%;
      opacity: 0;
    }
  
    .group {
      display: flex;
      flex-direction: column;
      position: relative;
      /* display: flex; */
  
      .title {
        margin: 0 0 4px 0;
        padding: 0 3px 0 3px;
        font-weight: 600;
        background-color: rgb(251 208 208);
      }
    }

    .i-lab {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 0 5px;
    
      &:has(.item:checked) {
        background: rgb(245 185 225);
    
        &::after {
          display: block;
          content: '✅';
        }
      }
    }

    .item {
      cursor: pointer;
    }
  }

  label:has(.item) {
    margin: 0 0 0 5px;
    padding: 0 3px 0 3px;
    border-radius: 3px;

    &:hover {
      background-color: rgb(219 208 233);
      box-shadow: 0 0 1px 1px rgb(150 150 150);
    }
  }

  &:focus {
    .main {
      &::after {
        content: '⬆';
      }
    }
    .items {
      display: flex;
    }
  }

  .list {
    display: none;
    flex-direction: column;
    width: max-content;
  }
}
}
</style>
