export const db = {
  'Evolution': {
    'EvoBalance': {
      'ММ': [
        {
          name:'Аддон',
          slot:["Корпус"],
          races:["Малоки","Фэяне","Гаальцы","Люди"],
          type:'Микромодуль',
          description:`Пристраивает к корпусу дополнительный грузовой отсек, увеличивая его вместимость на 15%. По каким-то причинам, применение к пеленгским корпусам невозможно.`,
          stats: {
            'Размер корпуса': ["+15","%"]
          },
        },
        {
          name:'Акринатор',
          slot:["Топливный бак","Двигатель","Радар","Сканер","Ремонтный дроид","Захват","Генератор защитного поля"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы","Пиратский клан","Доминаторы"],
          type:'Микромодуль',
          description:`Ручной работы. Новейшая экспериментальная разработка техников Коалиции в области акриновых технологий, произведённая в единственном экземпляре. Увеличивает все особые эффекты акринового оборудования более чем в два раза и нейтрализует все его негативные эффекты. Размер и стоимость оборудования при этом увеличиваются в два раза. Из-за конфликта устаревших разъёмов, установка Акринатора в корпус не представляется возможной.`,
          stats: {
            'Акриновые бонусы': ["+150","%"],
            'Акриновые штрафы': ["-100","%"],
            'Размер': ["+100","%"],
            'Стоимость': ["+100","%"]
          },
        },
        {
          name:'Антент',
          slot:["Радар","Сканер"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Улучшает характеристики сканирующих и радарных устройств. Встраивается в антенный усилитель, увеличивая его мощность.`,
          stats: {
            'Дальность радара': ["300","млн. км."],
            'Мощность сканера': ["3","ед."]
          },
        },
        {
          name:'Антифэй',
          slot:["Ракетное оружие"],
          races:["Малоки","Пеленги","Люди","Гаальцы"],
          type:'Микромодуль',
          description:`Увеличивает заряд ракетного оружия. По неизвестным причинам, отвергается фэянским оборудованием.`,
          stats: {
            'Сила ракетного оружия': ["35","ед."]
          },
        },
        {
          name:'Аппер',
          slot:["Двигатель","Радар"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Наращивает мощность двигателя или радара, за счёт увеличения эффективности преобразования энергии.`,
          stats: {
            'Дальность радара': ["380","млн. км."],
            'Скорость двигателя': ["55","ед."]
          },
        },
        {
          name:'Армор',
          slot:["Корпус"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Встраивается в корпус. Активирует два слота для оружия.`,
          stats: {
            
          },
        },
        {
          name:'Бач',
          slot:["Топливный бак"],
          races:["Люди","Гаальцы"],
          type:'Микромодуль',
          description:`Специальные наноботы производят изменения молекулярной структуры топлива, уменьшая его реальный объём, что позволяет хранить большее количество топлива при неизменных характеристиках самого топливного оборудования. Совместим с оборудованием гаальцев и людей.`,
          stats: {
            'Объём бака': ["25","ед."]
          },
        },
        {
          name:'Берсерк',
          slot:["Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Дополняет орудийные системы довольно массивным ускорителем частиц и генератором антиматерии, существенно повышая их боевые характеристики.`,
          stats: {
            'Дальность стрельбы': ["20","ед."],
            'Размер': ["+30","%"],
            'Сила осколочного оружия': ["7","ед."],
            'Сила ракетного оружия': ["15","ед."],
            'Сила энергетического оружия': ["5","ед."]
          },
        },
        {
          name:'Боттер',
          slot:["Ремонтный дроид"],
          races:["Фэяне"],
          type:'Микромодуль',
          description:`Обновляет программу ремонтного дроида, благодаря чему его действия становятся более точными и эффективными, а также на 67% возрастает надёжность. Совместим только с фэянскими типами дроидов.`,
          stats: {
            'Надёжность': ["+67","%"],
            'Эффективность дроида': ["7","ед."]
          },
        },
        {
          name:'Броинг',
          slot:["Корпус","Генератор защитного поля"],
          races:["Пеленги"],
          type:'Микромодуль',
          description:`Уникальный и дорогостоящий генератор создаёт поверхностное отталкивающее поле с высокой степенью поглощения кинетической и тепловой энергии, и может использоваться для увеличения защитных свойств корпуса и генератора поля. Совместим только с устройствами пеленгского производства.`,
          stats: {
            'Броня корпуса': ["7","ед."],
            'Генератор поля': ["9","ед."],
            'Стоимость': ["+40","%"]
          },
        },
        {
          name:'Бронд',
          slot:["Корпус","Генератор защитного поля"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Способен с большой скоростью генерировать сверхплотные частицы, используемые в защите корабля. Совместим с корпусом и генератором защитного поля.`,
          stats: {
            'Броня корпуса': ["2","ед."],
            'Генератор поля': ["2","ед."]
          },
        },
        {
          name:'Бронепакет',
          slot:["Корпус"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Оснащает корпус корабля дополнительными листами композитной брони. Размещаемые внутри корпуса гироскопические шарнирные крепления пластин брони и гасители удара занимают довольно большой объём, существенно снижая вместимость корабля.`,
          stats: {
            'Броня корпуса': ["4","ед."],
            'Размер': ["-10","%"]
          },
        },
        {
          name:'Винтарь',
          slot:["Осколочное оружие"],
          races:["Малоки","Пеленги"],
          type:'Микромодуль',
          description:`Повышает бризантность снарядов осколочного оружия путём увеличения критической массы взрывного наполнителя. Стоимость такого оружия также возрастает. Подходит только для малокских и пеленгских типов орудий.`,
          stats: {
            'Сила осколочного оружия': ["45","ед."],
            'Стоимость': ["+50","%"]
          },
        },
        {
          name:'Вихрь',
          slot:["Двигатель"],
          races:["Люди"],
          type:'Микромодуль',
          description:`Содержит дополнительный, высокоточный микроконтроллер для навигационных систем двигателя. За счёт более высокой точности расчётов увеличивается скорость и дальность прыжка. Совместим только с двигателями людей.`,
          stats: {
            'Дальность прыжка': ["3","пк."],
            'Скорость двигателя': ["20","ед."]
          },
        },
        {
          name:'Гезават',
          slot:["Корпус"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Встраивается в корпус. Активирует слот захвата и генератора защитного поля.`,
          stats: {
            
          },
        },
        {
          name:'Горанд',
          slot:["Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Заменяет компоненты вооружения на нанотехнологичные аналоги, увеличивая надёжность на 88%, а стоимость - на 30%.`,
          stats: {
            'Надёжность': ["+88","%"],
            'Стоимость': ["+30","%"]
          },
        },
        {
          name:'Громодрын',
          slot:["Ремонтный дроид"],
          races:["Пеленги","Малоки"],
          type:'Микромодуль',
          description:`Убирает из дроида лишние сервисные блоки (защиту от шума, радиации, дурака, и т.п.), заменяя их дополнительными ремонтными модулями. Эта операция, увеличивающая эффективность дроида за счёт его стоимости, выполнима лишь на дроидах малокского и пеленгского производства.`,
          stats: {
            'Стоимость': ["-15","%"],
            'Эффективность дроида': ["8","ед."]
          },
        },
        {
          name:'Гугаал',
          slot:["Двигатель"],
          races:["Гаальцы"],
          type:'Микромодуль',
          description:`Позволяет максимально реализовать скоростной потенциал гаальских двигателей, заложенный конструктивно, однако не используемый в современных двигательных системах. Конструктивные изменения незначительны, однако они, тем не менее, увеличивают стоимость двигателя.`,
          stats: {
            'Скорость двигателя': ["80","ед."],
            'Стоимость': ["+25","%"]
          },
        },
        {
          name:'Дальномёт',
          slot:["Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Снижает инерционность корректирующих систем орудия, повышая дальность стрельбы.`,
          stats: {
            'Дальность стрельбы': ["23","ед."]
          },
        },
        {
          name:'Дальпун',
          slot:["Энергетическое оружие","Осколочное оружие"],
          races:["Малоки","Пеленги","Люди","Фэяне"],
          type:'Микромодуль',
          description:`Надстраивает атмическую систему наведения орудий, увеличивая радиус поражения энергетических и осколочных видов вооружения. Увеличивает занимаемый объём за счёт встроенного атмического сканера. Несовместим с чересчур миролюбивыми гаальскими типами оружия.`,
          stats: {
            'Дальность стрельбы': ["100","ед."],
            'Размер': ["+35","%"]
          },
        },
        {
          name:'Дальстар',
          slot:["Двигатель","Радар","Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Пеленги"],
          type:'Микромодуль',
          description:`Позволяет увеличить дальность действия оборудования за счёт устранения статистической ошибки вычислений, проявляющейся в пеленгском оборудовании при расчётах в предельных интервалах.`,
          stats: {
            'Дальность прыжка': ["8","пк."],
            'Дальность радара': ["500","млн. км."],
            'Дальность стрельбы': ["30","ед."]
          },
        },
        {
          name:'Двима',
          slot:["Двигатель"],
          races:["Малоки"],
          type:'Микромодуль',
          description:`Модифицирует двигательные кристальные наполнители, увеличивая скорость и дальность прыжка. Подходит только для малокских двигателей.`,
          stats: {
            'Дальность прыжка': ["8","пк."],
            'Скорость двигателя': ["120","ед."]
          },
        },
        {
          name:'Джампгейтор 3.11',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Патчит программную часть модуля навигации и моментальной коррекции двигателя до версии 3.11, увеличивая тем самым дальность прыжка.`,
          stats: {
            'Дальность прыжка': ["12","пк."],
            'Стоимость': ["+40","%"]
          },
        },
        {
          name:'Джейк',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Заменяет топливные фильтры, повышая степень очистки горючего, что ведёт к увеличению скорости двигателя.`,
          stats: {
            'Скорость двигателя': ["150","ед."]
          },
        },
        {
          name:'Диамант',
          slot:["Корпус"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Встраивается в корпус. Активирует слот ремонтного дроида и захвата.`,
          stats: {
            
          },
        },
        {
          name:'Диощит',
          slot:["Генератор защитного поля","Ракетное оружие"],
          races:["Фэяне","Люди"],
          type:'Микромодуль',
          description:`Заменяет движущиеся части генератора защитного поля или ракетного оружия на элементы доминаторного принципа действия. Совместная разработка фэян и людей, работает только с оборудованием этих рас.`,
          stats: {
            'Генератор поля': ["5","ед."],
            'Сила ракетного оружия': ["23","ед."]
          },
        },
        {
          name:'Дронз',
          slot:["Ремонтный дроид"],
          races:["Малоки","Пеленги"],
          type:'Микромодуль',
          description:`Совершенствует аккумуляторы векторного типа, благодаря чему дроид может дольше работать и, следовательно, устранять больше повреждений. Бесполезен для человеческих, фэянских и гаальских моделей дроидов.`,
          stats: {
            'Эффективность дроида': ["18","ед."]
          },
        },
        {
          name:'Дроннер',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Повышает ремонтные возможности дроида, заменяя часть деталей более эффективными, но и более тяжёлыми. Вес дроида (во всех смыслах) увеличивается.`,
          stats: {
            'Размер': ["+50","%"],
            'Эффективность дроида': ["16","ед."]
          },
        },
        {
          name:'Жампа',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Заменяет преобразователь двигателя на надёжную модель нового поколения, позволяющую на короткое время увеличить энергетический потенциал топлива. При этом увеличивается дальность прыжка.`,
          stats: {
            'Дальность прыжка': ["8","пк."],
            'Надёжность': ["+30","%"]
          },
        },
        {
          name:'Жжения',
          slot:["Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Гаальцы","Пеленги"],
          type:'Микромодуль',
          description:`Добавляет дополнительные поражающие элементы к снарядам, используя частицы антивещества. Значительно увеличивает мощность любого оружия. Совместим с гаальским и пеленгским типами вооружения.`,
          stats: {
            'Сила осколочного оружия': ["37","ед."],
            'Сила ракетного оружия': ["37","ед."],
            'Сила энергетического оружия': ["26","ед."]
          },
        },
        {
          name:'Иикебанит',
          slot:[],
          races:["Гаальцы"],
          type:'Микромодуль',
          description:`Наносит декоративное напыление, придающее любому гаальскому оборудованию расцветку завершённой гаальской Иике-Бааны. Стоимость такого оборудования существенно возрастает.`,
          stats: {
            'Стоимость': ["+120","%"]
          },
        },
        {
          name:'Импелган',
          slot:["Сканер"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Заменяет магнитный генератор сканера на более дорогой импульсно-гравитационный, тем самым увеличивая мощность прибора.`,
          stats: {
            'Мощность сканера': ["7","ед."],
            'Стоимость': ["+25","%"]
          },
        },
        {
          name:'Интегратор',
          slot:["Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Повышает поражающий коэффициент оружия путём сингулярного усиления начального импульса.`,
          stats: {
            'Размер': ["+25","%"],
            'Сила осколочного оружия': ["23","ед."],
            'Сила ракетного оружия': ["23","ед."],
            'Сила энергетического оружия': ["16","ед."]
          },
        },
        {
          name:'Интердизайн',
          slot:["Корпус"],
          races:["Любые"],
          type:'Микромодуль',
          description:`За счёт более рационального переоборудования помещений корабля вместимость корпуса увеличивается.`,
          stats: {
            'Размер корпуса': ["+7","%"]
          },
        },
        {
          name:'Клейн',
          slot:["Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Наращивает мощность любого оружия, при этом уменьшая дальность его стрельбы.`,
          stats: {
            'Дальность стрельбы': ["-20","ед."],
            'Сила осколочного оружия': ["16","ед."],
            'Сила ракетного оружия': ["12","ед."],
            'Сила энергетического оружия': ["11","ед."]
          },
        },
        {
          name:'Колебратор',
          slot:["Радар","Захват","Генератор защитного поля"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Улучшает характеристики волновых излучателей устройств. Увеличивает мощность оборудования, использующего непрерывное излучение и поля с волновыми характеристиками, такого как радар, захват и генератор защитного поля.`,
          stats: {
            'Генератор поля': ["3","ед."],
            'Дальность радара': ["540","млн. км."],
            'Мощность захвата': ["40","ед."]
          },
        },
        {
          name:'Конвектор',
          slot:["Радар","Сканер"],
          races:["Люди"],
          type:'Микромодуль',
          description:`Встраивается в механическую часть устройства. Увеличивает мощность сканера или дальность радара. Совместим только с человеческим оборудованием.`,
          stats: {
            'Дальность радара': ["700","млн. км."],
            'Мощность сканера': ["7","ед."]
          },
        },
        {
          name:'Крепчак',
          slot:["Корпус"],
          races:["Пеленги","Люди"],
          type:'Микромодуль',
          description:`Встраивается в корпус. Закрепляет свою микросеть отталкивающих стационарных наноботов, частично блокирующих получаемые повреждения. Совместим только с пеленгским и человеческим типом корпусов.`,
          stats: {
            'Броня корпуса': ["6","ед."],
            'Стоимость': ["+25","%"]
          },
        },
        {
          name:'Ламбалд',
          slot:["Энергетическое оружие","Осколочное оружие","Ракетное оружие","Топливный бак","Двигатель","Радар","Сканер","Ремонтный дроид","Захват","Генератор защитного поля"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Производит обработку частей оборудования фемтосекундными импульсами Л-поля, увеличивая надёжность на 150%. Из-за ограничения мощности излучения, взаимодействие с корпусом технически невозможно.`,
          stats: {
            'Надёжность': ["+150","%"]
          },
        },
        {
          name:'Магникум',
          slot:["Захват"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Увеличивает потенциал генерируемого захватом поля, повышая тем самым мощность прибора.`,
          stats: {
            'Дальность захвата': ["5","ед."],
            'Мощность захвата': ["35","ед."]
          },
        },
        {
          name:'Маформ',
          slot:["Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Малоки"],
          type:'Микромодуль',
          description:`Производит физическую деформацию компенсаторных модулей малокского вооружения в сторону увеличения, при этом возрастают наносимые повреждения и надёжность. Так как это один из самых дорогих узлов, стоимость оружия резко возрастает.`,
          stats: {
            'Надёжность': ["+82","%"],
            'Размер': ["+20","%"],
            'Сила осколочного оружия': ["20","ед."],
            'Сила ракетного оружия': ["20","ед."],
            'Сила энергетического оружия': ["14","ед."],
            'Стоимость': ["+60","%"]
          },
        },
        {
          name:'Микроразмерный',
          slot:["Топливный бак","Двигатель","Радар","Сканер","Ремонтный дроид","Захват","Генератор защитного поля"],
          races:["Малоки","Пеленги","Люди","Фэяне"],
          type:'Микромодуль',
          description:`Уменьшает расстояние в молекулярных сетках любого оборудования и компенсирует изменения внутримолекулярных сил. Не может применяться для корпуса и оружия, так как в этом случае теряется их функциональность. Микромодуль положительно влияет на износостойкость и позволяет уменьшить размер оборудования. Использование в гаальских устройствах невозможно.`,
          stats: {
            'Надёжность': ["+50","%"],
            'Размер': ["-35","%"]
          },
        },
        {
          name:'Микрострел',
          slot:["Двигатель","Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Люди","Фэяне","Гаальцы"],
          type:'Микромодуль',
          description:`Заменяет систему координат корректирующего модуля на фэянскую эклиптическую, увеличивая расстояние стрельбы орудий и дальность гиперпрыжка двигателя. Не подходит для использования в малокском и пеленгском оборудовании.`,
          stats: {
            'Дальность прыжка': ["5","пк."],
            'Дальность стрельбы': ["40","ед."]
          },
        },
        {
          name:'Минивизион',
          slot:["Радар"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Производит замену блока навигации радара на новый миниатюрный, сбалансированный и высокоэффективный элемент.`,
          stats: {
            'Дальность радара': ["800","млн. км."],
            'Размер': ["-15","%"]
          },
        },
        {
          name:'Миниразмерный',
          slot:["Топливный бак","Двигатель","Радар","Сканер","Ремонтный дроид","Захват","Генератор защитного поля","Осколочное оружие"],
          races:["Люди"],
          type:'Микромодуль',
          description:`Производит ряд замен крупных тексоэлементов на более компактные, но при этом очень дешёвые. Совместим только с оборудованием расы людей. Не взаимодействует с энергетическим и ракетным оружием, а также с корпусом.`,
          stats: {
            'Размер': ["-20","%"],
            'Стоимость': ["-35","%"]
          },
        },
        {
          name:'Объёмник',
          slot:["Топливный бак"],
          races:["Пеленги","Фэяне"],
          type:'Микромодуль',
          description:`Искривляет область пространства внутри резервуара, позволяя вместить больший объём топлива при неизменной массе и размере самого бака. Совместим с пеленгским и фэянским типом оборудования.`,
          stats: {
            'Объём бака': ["12","ед."]
          },
        },
        {
          name:'Оптиматор',
          slot:["Топливный бак","Двигатель","Радар","Сканер","Ремонтный дроид","Захват","Генератор защитного поля"],
          races:["Пеленги"],
          type:'Микромодуль',
          description:`Удаляет из пеленгского оборудования нефункциональные и декоративные узлы и детали, уменьшая его габаритные размеры и стоимость и повышая надёжность. Не применим для корпусов и орудийных систем.`,
          stats: {
            'Надёжность': ["+42","%"],
            'Размер': ["-25","%"],
            'Стоимость': ["-25","%"]
          },
        },
        {
          name:'Пакстон',
          slot:["Генератор защитного поля"],
          races:["Малоки"],
          type:'Микромодуль',
          description:`Позволяет более рационально перекомпоновать элементы генератора защиты, сделанного по малокской технологии. Это приводит к уменьшению размера устройства, при котором его работоспособность не нарушается, но общая мощность немного падает.`,
          stats: {
            'Генератор поля': ["-1","ед."],
            'Размер': ["-40","%"]
          },
        },
        {
          name:'Панцирь',
          slot:["Корпус"],
          races:["Малоки"],
          type:'Микромодуль',
          description:`Проводит высокочастотную бомбардировку корпуса энергоквантами, увеличивая защитные характеристики материала. Эта обработка входит в стандартные операции по изготовлению корпусов у всех рас, кроме малоков, поэтому эффективна только для малокских корпусов.`,
          stats: {
            'Броня корпуса': ["7","ед."]
          },
        },
        {
          name:'Парагон',
          slot:["Захват"],
          races:["Малоки","Люди","Фэяне","Гаальцы"],
          type:'Микромодуль',
          description:`Заменяет источник энергии захвата на более мощный, дорогой и тяжёлый агрегат. Не совместим с пеленгскими комплектующими.`,
          stats: {
            'Мощность захвата': ["67","ед."],
            'Размер': ["+10","%"],
            'Стоимость': ["+20","%"]
          },
        },
        {
          name:'Патч 0.95 бета',
          slot:["Двигатель","Радар","Сканер","Ремонтный дроид","Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Пеленги","Люди","Гаальцы"],
          type:'Микромодуль',
          description:`Содержит комплект перепрошивочных модулей для программного обеспечения вычислительных и корректирующих устройств некоторых типов оборудования. Патч увеличивает точность и скорость вычислений с плавающей запятой. Из-за своей сырости, патч конфликтует с программным обеспечением фэянского и малокского оборудования.`,
          stats: {
            'Дальность радара': ["700","млн. км."],
            'Дальность стрельбы': ["50","ед."],
            'Мощность сканера': ["7","ед."],
            'Скорость двигателя': ["105","ед."],
            'Эффективность дроида': ["17","ед."]
          },
        },
        {
          name:'Патч 0.96 альфа',
          slot:["Двигатель","Радар","Сканер","Ремонтный дроид","Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Содержит комплект перепрошивочных модулей для программного обеспечения вычислительных и корректирующих устройств некоторых типов оборудования. Патч увеличивает точность и скорость вычислений с плавающей запятой. Альфа-версия совместима с оборудованием всех рас.`,
          stats: {
            'Дальность радара': ["400","млн. км."],
            'Дальность стрельбы': ["30","ед."],
            'Мощность сканера': ["4","ед."],
            'Скорость двигателя': ["55","ед."],
            'Эффективность дроида': ["10","ед."]
          },
        },
        {
          name:'Перфорит',
          slot:["Корпус"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Встраивается в корпус. Активирует два слота для артефактов.`,
          stats: {
            
          },
        },
        {
          name:'Платинос',
          slot:[],
          races:["Любые"],
          type:'Микромодуль',
          description:`Покрывает оборудование тонким слоем платины, увеличивая тем самым его цену в глазах сумасшедших коллекционеров и любителей беспощадного гламура.`,
          stats: {
            'Стоимость': ["+35","%"]
          },
        },
        {
          name:'Поточный',
          slot:["Сканер"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Встраивается в поточный блок сканера и частично расширяет спектр принимаемых им волн.`,
          stats: {
            'Мощность сканера': ["3","ед."]
          },
        },
        {
          name:'Прозотор',
          slot:["Корпус"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Встраивается в корпус. Активирует один слот для оружия и один для артефакта.`,
          stats: {
            
          },
        },
        {
          name:'Ракетон',
          slot:["Энергетическое оружие","Ракетное оружие"],
          races:["Любые"],
          type:'Микромодуль',
          description:`, содержащий ненаправленные бета-проводники. Значительно увеличивает силу любого ракетного оружия или частично - энергетического.`,
          stats: {
            'Сила ракетного оружия': ["11","ед."],
            'Сила энергетического оружия': ["6","ед."]
          },
        },
        {
          name:'Раструг',
          slot:["Корпус"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Встраивается в корпус. Активирует слот ремонтного дроида и генератора защитного поля.`,
          stats: {
            
          },
        },
        {
          name:'Редуза',
          slot:["Двигатель"],
          races:["Пеленги"],
          type:'Микромодуль',
          description:`Уменьшает внутренний диаметр сопел двигателя путём наращивания композитных кристаллоидов, в результате чего увеличивается скорость истечения реактивной струи. Применим только для пеленгских двигателей.`,
          stats: {
            'Скорость двигателя': ["130","ед."]
          },
        },
        {
          name:'Ресиз',
          slot:["Топливный бак"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Размягчает и деформирует стенки топливного бака, увеличивая его внутренний объём.`,
          stats: {
            'Объём бака': ["8","ед."],
            'Размер': ["+20","%"]
          },
        },
        {
          name:'Сдвиг',
          slot:["Двигатель"],
          races:["Пеленги","Люди"],
          type:'Микромодуль',
          description:`Модифицирует систему разгона двигателя, тем самым увеличивая дальность прыжка в гиперпространстве. Адаптирован под пеленгские и человеческие двигатели.`,
          stats: {
            'Дальность прыжка': ["7","пк."]
          },
        },
        {
          name:'Сжим',
          slot:["Топливный бак","Двигатель","Радар","Сканер","Ремонтный дроид","Захват","Генератор защитного поля","Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Фэяне"],
          type:'Микромодуль',
          description:`Заменяет наиболее крупные платы их меньшими современными аналогами. Уменьшает размер любого оборудования или оружия, кроме корпуса. Совместим только с фэянскими типами оборудования.`,
          stats: {
            'Размер': ["-40","%"]
          },
        },
        {
          name:'Спаай',
          slot:["Радар","Сканер"],
          races:["Гаальцы"],
          type:'Микромодуль',
          description:`Квазиинтеллектуальный блок, значительно увеличивающий мощность гаальского радара или сканера, за счёт использования "псевдоинтуиции".`,
          stats: {
            'Дальность радара': ["730","млн. км."],
            'Мощность сканера': ["7","ед."]
          },
        },
        {
          name:'Сплинтер',
          slot:["Осколочное оружие"],
          races:["Малоки","Пеленги"],
          type:'Микромодуль',
          description:`Регулирует затворный механизм осколочного оружия, повышая эффективность стрельбы. Совместим с малокским и пеленгским вооружением.`,
          stats: {
            'Сила осколочного оружия': ["20","ед."]
          },
        },
        {
          name:'Стаксер',
          slot:["Двигатель"],
          races:["Малоки","Пеленги","Люди","Фэяне"],
          type:'Микромодуль',
          description:`За счёт оптимизации режимов работы двигателя увеличивает его надёжность и дальность прыжка. По условиям лицензионного соглашения несовместим с двигателями гаальского производства.`,
          stats: {
            'Дальность прыжка': ["3","пк."],
            'Надёжность': ["+25","%"]
          },
        },
        {
          name:'Стоплай',
          slot:["Топливный бак","Двигатель","Радар","Сканер","Ремонтный дроид","Захват","Генератор защитного поля"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Производит замену движущихся частей оборудования на более прочные фрагилиумные. Уменьшает размер и увеличивает стоимость оборудования. Не применим для орудийных систем и корпуса.`,
          stats: {
            'Размер': ["-30","%"],
            'Стоимость': ["+20","%"]
          },
        },
        {
          name:'Стратер',
          slot:["Двигатель"],
          races:["Малоки"],
          type:'Микромодуль',
          description:`Генерирует поле нулевого трения, снижая потери энергии двигателя на выходе, за счёт чего увеличиваются как скорость, так и дальность прыжка. Совместим только с малокским оборудованием.`,
          stats: {
            'Дальность прыжка': ["4","пк."],
            'Скорость двигателя': ["55","ед."]
          },
        },
        {
          name:'Тюринг',
          slot:["Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Заменяет массивную орудийную турель на силовую опору. Часть энергии генератора уходит на гашение отдачи, поэтому дальность и сила выстрела снижаются, зато орудие начинает занимать вдвое меньший объём.`,
          stats: {
            'Дальность стрельбы': ["-10","ед."],
            'Размер': ["-55","%"],
            'Сила осколочного оружия': ["-5","ед."],
            'Сила ракетного оружия': ["-7","ед."],
            'Сила энергетического оружия': ["-5","ед."]
          },
        },
        {
          name:'Универс',
          slot:[],
          races:["Любые"],
          type:'Микромодуль',
          description:`Встраивается в стандартный слот расширения любого оборудования. Активно участвует в работе устройства, оптимизируя вычисления и схемуфункционирования агрегата.`,
          stats: {
            'Броня корпуса': ["6","ед."],
            'Генератор поля': ["6","ед."],
            'Дальность радара': ["1000","млн. км."],
            'Мощность захвата': ["75","ед."],
            'Мощность сканера': ["10","ед."],
            'Объём бака': ["20","ед."],
            'Сила осколочного оружия': ["30","ед."],
            'Сила ракетного оружия': ["30","ед."],
            'Сила энергетического оружия': ["21","ед."],
            'Скорость двигателя': ["150","ед."],
            'Эффективность дроида': ["25","ед."]
          },
        },
        {
          name:'Форсер',
          slot:["Корпус"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Встраивается в корпус. Активирует слоты форсажа, радара и сканера.`,
          stats: {
            
          },
        },
        {
          name:'Фэйджоуль',
          slot:["Двигатель"],
          races:["Фэяне"],
          type:'Микромодуль',
          description:`Добавляется в навигационную систему двигателя, увеличивая тактовую частоту вычислительного блока устройства. Совместим только с фэянским оборудованием.`,
          stats: {
            'Дальность прыжка': ["5","пк."],
            'Размер': ["+20","%"],
            'Скорость двигателя': ["20","ед."]
          },
        },
        {
          name:'Хаб',
          slot:["Корпус"],
          races:["Фэяне","Люди"],
          type:'Микромодуль',
          description:`Пристраивает к корпусу дополнительный хаб-блок, увеличивая его объём. Изначально разработан исключительно для корпусов людей, но впоследствии фэяне также лицензировали его использование и адаптировали для своих кораблей.`,
          stats: {
            'Размер': ["+26","%"]
          },
        },
        {
          name:'Хокус',
          slot:["Захват"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Изменяет периодику колебаний волнового поля захвата в соответствии с характеристиками материала захватываемого объекта, увеличивая проникающую способность поля и, соответственно, мощность захвата.`,
          stats: {
            'Дальность захвата': ["-10","ед."],
            'Мощность захвата': ["85","ед."]
          },
        },
        {
          name:'Цент',
          slot:["Топливный бак","Двигатель","Радар","Сканер","Ремонтный дроид","Захват","Генератор защитного поля","Энергетическое оружие","Осколочное оружие","Ракетное оружие"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Влияет на составляющую материала любого предмета (кроме корпуса), несколько увеличивая его размер. Стоимость же, напротив, уменьшается в 3 раза, что приводит к выгодности последующего ремонта.`,
          stats: {
            'Размер': ["+15","%"],
            'Стоимость': ["-66","%"]
          },
        },
        {
          name:'Шелест',
          slot:["Генератор защитного поля"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Увеличивает эффективность фильтра помех генератора защитного поля. При этом активный микромодуль занимает довольно большой объём.`,
          stats: {
            'Генератор поля': ["3","ед."],
            'Размер': ["+25","%"]
          },
        },
        {
          name:'Эксплодер',
          slot:["Двигатель"],
          races:["Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Микромодуль',
          description:`Встраивается в двигатель. Дестабилизирует молекулярную структуру топлива, увеличивая его детонационные свойства. Это позволяет повысить скорость двигателя, однако из-за высоких перегрузок падает дальность гиперпрыжка. К сожалению, малокские двигатели не выдерживают возросших нагрузок.`,
          stats: {
            'Дальность прыжка': ["-6","пк."],
            'Скорость двигателя': ["160","ед."]
          },
        },
        {
          name:'Экстример',
          slot:["Корпус"],
          races:["Малоки"],
          type:'Микромодуль',
          description:`За счёт более рациональной программы использования внутреннего пространства грузового отсека и устранения нефункциональных конструкций увеличивает вместимость и стоимость корпуса корабля. Подходит только для малокских судов.`,
          stats: {
            'Размер': ["+30","%"],
            'Стоимость': ["+60","%"]
          },
        },
        {
          name:'Электро',
          slot:["Энергетическое оружие"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Воздействует на силу энергетического оружия, используя принцип умножения мощности генератора импульса.`,
          stats: {
            'Сила энергетического оружия': ["7","ед."]
          },
        },
        {
          name:'Энергос',
          slot:["Энергетическое оружие"],
          races:["Любые"],
          type:'Микромодуль',
          description:`Добавляется как вспомогательное устройство в синтезатор пучка для энергетических типов вооружения.`,
          stats: {
            'Сила энергетического оружия': ["12","ед."]
          },
        },
        {
          name:'Эскалибур',
          slot:["Энергетическое оружие"],
          races:["Люди"],
          type:'Микромодуль',
          description:`Изобретенный человеческими учёными, этот микромодуль модифицирует преобразователи энергетических орудий, существенно увеличивая их поражающую способность. Из патриотических соображений этот модуль совместим только с оборудованием людей.`,
          stats: {
            'Дальность стрельбы': ["30","ед."],
            'Сила энергетического оружия': ["10","ед."]
          },
        },
      ],
      'Корпусный акрин': [
        {
          name:'Быстрый корпус I',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`То, что этот корпус сконструирован обтекаемым, вовсе не влияет на его скорость, ведь в космосе нет сопротивления воздуха. Это лишь дань моде. На самом деле, прибавка в скорости достигается за счёт удачного расположения двигателя и усиленного впрыска топлива.`,
          stats: {
            'Скорость двигателя': ["200","ед."]
          },
        },
        {
          name:'Быстрый корпус II',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`То, что этот корпус сконструирован обтекаемым, вовсе не влияет на его скорость, ведь в космосе нет сопротивления воздуха. Это лишь дань моде. На самом деле, прибавка в скорости достигается за счёт удачного расположения двигателя и усиленного впрыска топлива.`,
          stats: {
            'Скорость двигателя': ["200","ед."]
          },
        },
        {
          name:'Быстрый корпус III',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`То, что этот корпус сконструирован обтекаемым, вовсе не влияет на его скорость, ведь в космосе нет сопротивления воздуха. Это лишь дань моде. На самом деле, прибавка в скорости достигается за счёт удачного расположения двигателя и усиленного впрыска топлива.`,
          stats: {
            'Скорость двигателя': ["200","ед."]
          },
        },
        {
          name:'Быстрый корпус IV',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`То, что этот корпус сконструирован обтекаемым, вовсе не влияет на его скорость, ведь в космосе нет сопротивления воздуха. Это лишь дань моде. На самом деле, прибавка в скорости достигается за счёт удачного расположения двигателя и усиленного впрыска топлива.`,
          stats: {
            'Скорость двигателя': ["200","ед."]
          },
        },
        {
          name:'Корпус "Безобиднейший корсар"',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Данный корпус был эксклюзивно прокачан известным пеленгским мастером Иц Забитом. Вся внутренняя структура корабля была спроектирована с целью обеспечения максимальной защиты. Его главный минус - сложные электронные системы управления, из-за медлительности и неточности которых влияние навыков пилота на ход боя ощутимо снижается.`,
          stats: {
            'Броня корпуса': ["4","ед."],
            'Генератор поля': ["11","ед."],
            'Навык техники': ["-1"],
            'Навык точности': ["-1"]
          },
        },
        {
          name:'Корпус "Болид"',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:``,
          stats: {
            'Броня корпуса': ["-6","ед."],
            'Дальность прыжка': ["7","пк."],
            'Дальность радара': ["550","млн. км."],
            'Скорость двигателя': ["600","ед."]
          },
        },
        {
          name:'Корпус "Броненосец" I',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Военная серия акриновых корпусов, предназначенная для проведения боевых операций по принципу="Прилетел, перестрелял всех вокруг, победил". В народе получила неофициальное прозвище "Утюг", из-за характерного внешнего вида и крайней неповоротливости при манёврах.`,
          stats: {
            'Генератор поля': ["3","ед."],
            'Навык манёвренности': ["-2"],
            'Сила осколочного оружия': ["11","ед."],
            'Сила энергетического оружия': ["8","ед."],
            'Скорость двигателя': ["-50","ед."]
          },
        },
        {
          name:'Корпус "Броненосец" II',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Военная серия акриновых корпусов, предназначенная для проведения боевых операций по принципу="Прилетел, перестрелял всех вокруг, победил". В народе получила неофициальное прозвище "Утюг", из-за характерного внешнего вида и крайней неповоротливости при манёврах.`,
          stats: {
            'Генератор поля': ["4","ед."],
            'Навык манёвренности': ["-2"],
            'Сила осколочного оружия': ["12","ед."],
            'Сила энергетического оружия': ["9","ед."],
            'Скорость двигателя': ["-100","ед."]
          },
        },
        {
          name:'Корпус "Броненосец" III',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Военная серия акриновых корпусов, предназначенная для проведения боевых операций по принципу="Прилетел, перестрелял всех вокруг, победил". В народе получила неофициальное прозвище "Утюг", из-за характерного внешнего вида и крайней неповоротливости при манёврах.`,
          stats: {
            'Генератор поля': ["5","ед."],
            'Навык манёвренности': ["-2"],
            'Сила осколочного оружия': ["14","ед."],
            'Сила энергетического оружия': ["10","ед."],
            'Скорость двигателя': ["-150","ед."]
          },
        },
        {
          name:'Корпус "Броненосец" IV',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Военная серия акриновых корпусов, предназначенная для проведения боевых операций по принципу="Прилетел, перестрелял всех вокруг, победил". В народе получила неофициальное прозвище "Утюг", из-за характерного внешнего вида и крайней неповоротливости при манёврах.`,
          stats: {
            'Генератор поля': ["3","ед."],
            'Навык манёвренности': ["-2"],
            'Сила осколочного оружия': ["11","ед."],
            'Сила энергетического оружия': ["8","ед."],
            'Скорость двигателя': ["-100","ед."]
          },
        },
        {
          name:'Корпус "Добрейший лякуша"',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Данный корпус был эксклюзивно прокачан известным пеленгским мастером Иц Забитом. Внутри имеется пространство лишь для самого необходимого оборудования, а вся энергосистема корабля настроена на обеспечение максимальной огневой мощи. Однозначно не подойдёт любителям мирно поторговать в сторонке.`,
          stats: {
            'Сила осколочного оружия': ["26","ед."],
            'Сила ракетного оружия': ["26","ед."],
            'Сила энергетического оружия': ["18","ед."]
          },
        },
        {
          name:'Корпус "Добрый лякуша" I',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Пиратская серия корпусов, разработанная специально для ведения космических боёв с Коалицией. Внутри имеется пространство лишь для самого необходимого оборудования, а вся энергосистема корабля настроена на обеспечение максимальной огневой мощи. Однозначно не подойдёт любителям мирно поторговать в сторонке.`,
          stats: {
            'Сила осколочного оружия': ["11","ед."],
            'Сила ракетного оружия': ["11","ед."],
            'Сила энергетического оружия': ["8","ед."]
          },
        },
        {
          name:'Корпус "Добрый лякуша" II',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Пиратская серия корпусов, разработанная специально для ведения космических боёв с Коалицией. Внутри имеется пространство лишь для самого необходимого оборудования, а вся энергосистема корабля настроена на обеспечение максимальной огневой мощи. Однозначно не подойдёт любителям мирно поторговать в сторонке.`,
          stats: {
            'Сила осколочного оружия': ["14","ед."],
            'Сила ракетного оружия': ["14","ед."],
            'Сила энергетического оружия': ["10","ед."]
          },
        },
        {
          name:'Корпус "Добрый лякуша" III',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Пиратская серия корпусов, разработанная специально для ведения космических боёв с Коалицией. Внутри имеется пространство лишь для самого необходимого оборудования, а вся энергосистема корабля настроена на обеспечение максимальной огневой мощи. Однозначно не подойдёт любителям мирно поторговать в сторонке.`,
          stats: {
            'Сила осколочного оружия': ["16","ед."],
            'Сила ракетного оружия': ["16","ед."],
            'Сила энергетического оружия': ["12","ед."]
          },
        },
        {
          name:'Корпус "Добрый лякуша" IV',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Пиратская серия корпусов, разработанная специально для ведения космических боёв с Коалицией. Внутри имеется пространство лишь для самого необходимого оборудования, а вся энергосистема корабля настроена на обеспечение максимальной огневой мощи. Однозначно не подойдёт любителям мирно поторговать в сторонке.`,
          stats: {
            'Сила осколочного оружия': ["12","ед."],
            'Сила ракетного оружия': ["12","ед."],
            'Сила энергетического оружия': ["9","ед."]
          },
        },
        {
          name:'Корпус "Железнобок"',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Экспериментальный образец корпуса серии "Броненосец" нового поколения. Таких корпусов нет в продаже по причине их чрезмерной дороговизны, но один экземпляр всё-таки был построен и успешно прошел все испытания, так что теперь ни у кого не повернётся язык назвать это чудо инженерной мысли "утюгом".`,
          stats: {
            'Генератор поля': ["8","ед."],
            'Навык манёвренности': ["-2"],
            'Сила осколочного оружия': ["20","ед."],
            'Сила энергетического оружия': ["14","ед."],
            'Скорость двигателя': ["-200","ед."]
          },
        },
        {
          name:'Корпус "Зарница"',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Агрессивный внешне спецкорпус, модернизированный в тюнинговой компании "Косозубая восмерёнка", и разработанный для тех, кто любит путешествовать по Галактике, предпочитая торговые операции и правительственные задания кровопролитным космическим боям.`,
          stats: {
            'Генератор поля': ["-12","ед."],
            'Дальность прыжка': ["16","пк."],
            'Дальность радара': ["1000","млн. км."],
            'Мощность сканера': ["10","ед."]
          },
        },
        {
          name:'Корпус "Мирный корсар" I',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Корпус для тех, кто обожает грабить мирные суда, но при этом не любит попадать под ответный удар военных Коалиции. Вся внутренняя структура корабля была спроектирована с целью обеспечения максимальной защиты. Его главный минус - сложные электронные системы управления, из-за медлительности и неточности которых влияние навыков пилота на ход боя ощутимо снижается.`,
          stats: {
            'Броня корпуса': ["2","ед."],
            'Генератор поля': ["4","ед."],
            'Навык техники': ["-1"],
            'Навык точности': ["-1"]
          },
        },
        {
          name:'Корпус "Мирный корсар" II',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Корпус для тех, кто обожает грабить мирные суда, но при этом не любит попадать под ответный удар военных Коалиции. Вся внутренняя структура корабля была спроектирована с целью обеспечения максимальной защиты. Его главный минус - сложные электронные системы управления, из-за медлительности и неточности которых влияние навыков пилота на ход боя ощутимо снижается.`,
          stats: {
            'Броня корпуса': ["2","ед."],
            'Генератор поля': ["6","ед."],
            'Навык техники': ["-1"],
            'Навык точности': ["-1"]
          },
        },
        {
          name:'Корпус "Мирный корсар" III',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Корпус для тех, кто обожает грабить мирные суда, но при этом не любит попадать под ответный удар военных Коалиции. Вся внутренняя структура корабля была спроектирована с целью обеспечения максимальной защиты. Его главный минус - сложные электронные системы управления, из-за медлительности и неточности которых влияние навыков пилота на ход боя ощутимо снижается.`,
          stats: {
            'Броня корпуса': ["3","ед."],
            'Генератор поля': ["9","ед."],
            'Навык техники': ["-1"],
            'Навык точности': ["-1"]
          },
        },
        {
          name:'Корпус "Мирный корсар" IV',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Корпус для тех, кто обожает грабить мирные суда, но при этом не любит попадать под ответный удар военных Коалиции. Вся внутренняя структура корабля была спроектирована с целью обеспечения максимальной защиты. Его главный минус - сложные электронные системы управления, из-за медлительности и неточности которых влияние навыков пилота на ход боя ощутимо снижается.`,
          stats: {
            'Броня корпуса': ["2","ед."],
            'Генератор поля': ["7","ед."],
            'Навык техники': ["-1"],
            'Навык точности': ["-1"]
          },
        },
        {
          name:'Корпус "Молния" I',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Агрессивный внешне спецкорпус для тех, кто любит путешествовать по Галактике, предпочитая торговые операции и правительственные задания ожесточённым космическим перестрелкам.`,
          stats: {
            'Дальность прыжка': ["7","пк."],
            'Дальность радара': ["200","млн. км."],
            'Мощность сканера': ["3","ед."]
          },
        },
        {
          name:'Корпус "Молния" II',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Агрессивный внешне спецкорпус для тех, кто любит путешествовать по Галактике, предпочитая торговые операции и правительственные задания ожесточённым космическим перестрелкам.`,
          stats: {
            'Дальность прыжка': ["8","пк."],
            'Дальность радара': ["300","млн. км."],
            'Мощность сканера': ["4","ед."]
          },
        },
        {
          name:'Корпус "Молния" III',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Агрессивный внешне спецкорпус для тех, кто любит путешествовать по Галактике, предпочитая торговые операции и правительственные задания ожесточённым космическим перестрелкам.`,
          stats: {
            'Генератор поля': ["-8","ед."],
            'Дальность прыжка': ["10","пк."],
            'Дальность радара': ["400","млн. км."],
            'Мощность сканера': ["6","ед."]
          },
        },
        {
          name:'Корпус "Молния" IV',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Агрессивный внешне спецкорпус для тех, кто любит путешествовать по Галактике, предпочитая торговые операции и правительственные задания ожесточённым космическим перестрелкам.`,
          stats: {
            'Генератор поля': ["-6","ед."],
            'Дальность прыжка': ["9","пк."],
            'Дальность радара': ["300","млн. км."],
            'Мощность сканера': ["5","ед."]
          },
        },
        {
          name:'Корпус "Скала"',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:``,
          stats: {
            'Броня корпуса': ["5","ед."],
            'Дальность радара': ["-600","млн. км."],
            'Мощность сканера': ["-5","ед."],
            'Навык манёвренности': ["2"],
            'Навык обаяния': ["-1"],
            'Навык техники': ["1"],
            'Навык торговли': ["-1"],
            'Скорость двигателя': ["-100","ед."],
            'Эффективность дроида': ["30","ед."]
          },
        },
        {
          name:'Корпус "Убийца"',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:``,
          stats: {
            'Навык манёвренности': ["-1"],
            'Навык обаяния': ["-1"],
            'Навык торговли': ["-1"],
            'Навык точности': ["1"],
            'Сила осколочного оружия': ["16","ед."],
            'Сила ракетного оружия': ["16","ед."],
            'Сила энергетического оружия': ["12","ед."],
            'Скорость двигателя': ["150","ед."]
          },
        },
        {
          name:'Корпус "Ушастик" I',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Первая серия, позиционируемая как "дамский" тип корпуса и ласково называемая "Ушастиком" за свой внешний вид. Пускай женщин пилотов ещё пока совсем мало, но их наверняка станет больше, как только они узнают, что в суровом патриархальном мире их вкусы хоть кого-то заинтересовали. Как ни странно, серия была разработана фэянами.`,
          stats: {
            'Навык обаяния': ["1"],
            'Сила ракетного оружия': ["6","ед."]
          },
        },
        {
          name:'Корпус "Ушастик" II',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Первая серия, позиционируемая как "дамский" тип корпуса и ласково называемая "Ушастиком" за свой внешний вид. Пускай женщин пилотов ещё пока совсем мало, но их наверняка станет больше, как только они узнают, что в суровом патриархальном мире их вкусы хоть кого-то заинтересовали. Как ни странно, серия была разработана фэянами.`,
          stats: {
            'Навык обаяния': ["1"],
            'Сила ракетного оружия': ["8","ед."]
          },
        },
        {
          name:'Корпус "Ушастик" III',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Первая серия, позиционируемая как "дамский" тип корпуса и ласково называемая "Ушастиком" за свой внешний вид. Пускай женщин пилотов ещё пока совсем мало, но их наверняка станет больше, как только они узнают, что в суровом патриархальном мире их вкусы хоть кого-то заинтересовали. Как ни странно, серия была разработана фэянами.`,
          stats: {
            'Навык обаяния': ["2"],
            'Сила ракетного оружия': ["8","ед."]
          },
        },
        {
          name:'Корпус "Ушастик" IV',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Первая серия, позиционируемая как "дамский" тип корпуса и ласково называемая "Ушастиком" за свой внешний вид. Пускай женщин пилотов ещё пока совсем мало, но их наверняка станет больше, как только они узнают, что в суровом патриархальном мире их вкусы хоть кого-то заинтересовали. Как ни странно, серия была разработана фэянами.`,
          stats: {
            'Навык обаяния': ["1"],
            'Сила ракетного оружия': ["7","ед."]
          },
        },
        {
          name:'Корпус "Чебурашка"',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Данный эксклюзивный корпус получен прямиком из дизайнерского ателье Фаринапини, где он был подвергнут значительной переработке. Кабина пилота убрана в кожу и дерево светлых тонов, а также обзавелась собственным баром и стереосистемой класса Hi-Hi. Снаружи корпус украшен ювелирными камнями от Свароковски и покрыт слоем специальной термозащиты, которая не позволит всей этой красоте отвалиться при первом же заходе в плотные слои атмосферы.`,
          stats: {
            'Навык обаяния': ["3"],
            'Сила ракетного оружия': ["22","ед."]
          },
        },
        {
          name:'Корпус "Честнейший ренегал"',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Данный корпус был эксклюзивно прокачан известным пеленгским мастером Иц Забитом. Благодаря инновационной технологии с шестью крыльями, создаёт вокруг корабля особое энергетическое поле, снижающее его массу, увеличивающее дальность гиперпрыжка и, как приятный бонус, улучшающее работу бортового захвата. Неприятный же нюанс заключается в том, что владельцу такого корпуса почти никто не предоставляет скидок банально из зависти.`,
          stats: {
            'Дальность захвата': ["40","ед."],
            'Дальность прыжка': ["10","пк."],
            'Масса корабля': ["-35","%"],
            'Навык торговли': ["-3"]
          },
        },
        {
          name:'Корпус "Честный ренегал" I',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Данный корпус - новейшая разработка гаальских учёных, выкраденная пиратами прямиком с испытательного полигона. Благодаря инновационной технологии с шестью крыльями, создаёт вокруг корабля особое энергетическое поле, снижающее его массу, увеличивающее дальность гиперпрыжка и, как приятный бонус, улучшающее работу бортового захвата. Неприятный же нюанс заключается в том, что владельцу такого корпуса почти никто не предоставляет скидок банально из зависти.`,
          stats: {
            'Дальность захвата': ["23","ед."],
            'Дальность прыжка': ["4","пк."],
            'Масса корабля': ["-10","%"],
            'Навык торговли': ["-2"]
          },
        },
        {
          name:'Корпус "Честный ренегал" II',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Данный корпус - новейшая разработка гаальских учёных, выкраденная пиратами прямиком с испытательного полигона. Благодаря инновационной технологии с шестью крыльями, создаёт вокруг корабля особое энергетическое поле, снижающее его массу, увеличивающее дальность гиперпрыжка и, как приятный бонус, улучшающее работу бортового захвата. Неприятный же нюанс заключается в том, что владельцу такого корпуса почти никто не предоставляет скидок банально из зависти.`,
          stats: {
            'Дальность захвата': ["30","ед."],
            'Дальность прыжка': ["5","пк."],
            'Масса корабля': ["-15","%"],
            'Навык торговли': ["-3"]
          },
        },
        {
          name:'Корпус "Честный ренегал" III',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Данный корпус - новейшая разработка гаальских учёных, выкраденная пиратами прямиком с испытательного полигона. Благодаря инновационной технологии с шестью крыльями, создаёт вокруг корабля особое энергетическое поле, снижающее его массу, увеличивающее дальность гиперпрыжка и, как приятный бонус, улучшающее работу бортового захвата. Неприятный же нюанс заключается в том, что владельцу такого корпуса почти никто не предоставляет скидок банально из зависти.`,
          stats: {
            'Дальность захвата': ["37","ед."],
            'Дальность прыжка': ["6","пк."],
            'Масса корабля': ["-20","%"],
            'Навык торговли': ["-3"]
          },
        },
        {
          name:'Корпус "Честный ренегал" IV',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Данный корпус - новейшая разработка гаальских учёных, выкраденная пиратами прямиком с испытательного полигона. Благодаря инновационной технологии с шестью крыльями, создаёт вокруг корабля особое энергетическое поле, снижающее его массу, увеличивающее дальность гиперпрыжка и, как приятный бонус, улучшающее работу бортового захвата. Неприятный же нюанс заключается в том, что владельцу такого корпуса почти никто не предоставляет скидок банально из зависти.`,
          stats: {
            'Дальность захвата': ["30","ед."],
            'Дальность прыжка': ["5","пк."],
            'Масса корабля': ["-15","%"],
            'Навык торговли': ["-3"]
          },
        },
        {
          name:'Миоплазменный корпус I',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["8","ед."],
            'Сила энергетического оружия': ["6","ед."],
            'Скорость двигателя': ["-20","ед."]
          },
        },
        {
          name:'Миоплазменный корпус II',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["12","ед."],
            'Сила энергетического оружия': ["9","ед."],
            'Скорость двигателя': ["-40","ед."]
          },
        },
        {
          name:'Миоплазменный корпус III',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["16","ед."],
            'Сила энергетического оружия': ["12","ед."],
            'Скорость двигателя': ["-60","ед."]
          },
        },
        {
          name:'Миоплазменный корпус IV',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["16","ед."],
            'Сила энергетического оружия': ["12","ед."],
            'Скорость двигателя': ["-40","ед."]
          },
        },
        {
          name:'Неуязвимый корпус I',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Разработанный фэянскими спецами корпус с усиленной защитой. Специальная акриновая прослойка между бронелистами корпуса позволяет добиться большей эффективности от работы генератора защитного поля.`,
          stats: {
            'Генератор поля': ["4","ед."]
          },
        },
        {
          name:'Неуязвимый корпус II',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Разработанный фэянскими спецами корпус с усиленной защитой. Специальная акриновая прослойка между бронелистами корпуса позволяет добиться большей эффективности от работы генератора защитного поля.`,
          stats: {
            'Генератор поля': ["6","ед."]
          },
        },
        {
          name:'Неуязвимый корпус III',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Разработанный фэянскими спецами корпус с усиленной защитой. Специальная акриновая прослойка между бронелистами корпуса позволяет добиться большей эффективности от работы генератора защитного поля.`,
          stats: {
            'Генератор поля': ["9","ед."]
          },
        },
        {
          name:'Неуязвимый корпус IV',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Разработанный фэянскими спецами корпус с усиленной защитой. Специальная акриновая прослойка между бронелистами корпуса позволяет добиться большей эффективности от работы генератора защитного поля.`,
          stats: {
            'Генератор поля': ["6","ед."]
          },
        },
        {
          name:'Пигамарный корпус I',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-3","ед."],
            'Дальность радара': ["-300","млн. км."],
            'Сила ракетного оружия': ["18","ед."]
          },
        },
        {
          name:'Пигамарный корпус II',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-5","ед."],
            'Дальность радара': ["-450","млн. км."],
            'Сила ракетного оружия': ["26","ед."]
          },
        },
        {
          name:'Пигамарный корпус III',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-7","ед."],
            'Дальность радара': ["-600","млн. км."],
            'Сила ракетного оружия': ["36","ед."]
          },
        },
        {
          name:'Пигамарный корпус IV',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-5","ед."],
            'Дальность радара': ["-450","млн. км."],
            'Сила ракетного оружия': ["30","ед."]
          },
        },
        {
          name:'Ремонтный корпус I',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Данный корпус был разработан специально для обеспечения лучшей работоспособности ремонтного дроида. Его гнездо расположено максимально удобно, а ко всем уязвимым деталям имеется лёгкий доступ. Вследствие этого в данном корпусе дроид работает с большей эффективностью, чем на остальных кораблях.`,
          stats: {
            'Эффективность дроида': ["20","ед."]
          },
        },
        {
          name:'Ремонтный корпус II',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Данный корпус был разработан специально для обеспечения лучшей работоспособности ремонтного дроида. Его гнездо расположено максимально удобно, а ко всем уязвимым деталям имеется лёгкий доступ. Вследствие этого в данном корпусе дроид работает с большей эффективностью, чем на остальных кораблях.`,
          stats: {
            'Эффективность дроида': ["30","ед."]
          },
        },
        {
          name:'Ремонтный корпус III',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Данный корпус был разработан специально для обеспечения лучшей работоспособности ремонтного дроида. Его гнездо расположено максимально удобно, а ко всем уязвимым деталям имеется лёгкий доступ. Вследствие этого в данном корпусе дроид работает с большей эффективностью, чем на остальных кораблях.`,
          stats: {
            'Эффективность дроида': ["40","ед."]
          },
        },
        {
          name:'Ремонтный корпус IV',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Данный корпус был разработан специально для обеспечения лучшей работоспособности ремонтного дроида. Его гнездо расположено максимально удобно, а ко всем уязвимым деталям имеется лёгкий доступ. Вследствие этого в данном корпусе дроид работает с большей эффективностью, чем на остальных кораблях.`,
          stats: {
            'Эффективность дроида': ["30","ед."]
          },
        },
        {
          name:'Тень Империи',
          slot:["Корпус"],
          races:["Любые"],
          type:'Корпусный акрин',
          description:`Современные технологии смогли адаптировать доисторического великана для использования рейнджерами. Тем не менее, внутри периодически раздаётся странная музыка, напоминающая марш, а боевая мощь этой махины продолжает поражать даже самых прожжённых вояк.`,
          stats: {
            'Навык обаяния': ["-2"]
          },
        },
      ],
      'Акрин оборудования': [
        {
          name:'Апгрейтинговый генератор защиты I',
          slot:["Генератор защитного поля"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["10","ед."],
            'Мощность захвата': ["15","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый генератор защиты II',
          slot:["Генератор защитного поля"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["13","ед."],
            'Мощность захвата': ["19","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый генератор защиты III',
          slot:["Генератор защитного поля"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["15","ед."],
            'Мощность захвата': ["25","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый двигатель I',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["10","ед."],
            'Мощность захвата': ["15","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый двигатель II',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["13","ед."],
            'Мощность захвата': ["19","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый двигатель III',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["15","ед."],
            'Мощность захвата': ["25","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый захват I',
          slot:["Захват"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["10","ед."],
            'Мощность захвата': ["15","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый захват II',
          slot:["Захват"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["13","ед."],
            'Мощность захвата': ["19","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый захват III',
          slot:["Захват"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["15","ед."],
            'Мощность захвата': ["25","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый радар I',
          slot:["Радар"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["10","ед."],
            'Мощность захвата': ["15","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый радар II',
          slot:["Радар"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["13","ед."],
            'Мощность захвата': ["19","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый радар III',
          slot:["Радар"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["15","ед."],
            'Мощность захвата': ["25","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый сканер I',
          slot:["Сканер"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["10","ед."],
            'Мощность захвата': ["15","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый сканер II',
          slot:["Сканер"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["13","ед."],
            'Мощность захвата': ["19","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый сканер III',
          slot:["Сканер"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["15","ед."],
            'Мощность захвата': ["25","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый топливный бак I',
          slot:["Топливный бак"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["10","ед."],
            'Мощность захвата': ["15","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый топливный бак II',
          slot:["Топливный бак"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["13","ед."],
            'Мощность захвата': ["19","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Апгрейтинговый топливный бак III',
          slot:["Топливный бак"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Производимое пиратскими техниками оборудование, собирается преимущественно из старых запчастей и разукрашивается в яркие цвета для придания товарного вида. Оно дешёвое, компактное и потребляет мало энергии, что полезно для работы захвата, однако имейте в виду - мало кто любит пиратов.`,
          stats: {
            'Дальность захвата': ["15","ед."],
            'Мощность захвата': ["25","ед."],
            'Навык обаяния': ["-2"]
          },
        },
        {
          name:'Биогенный дроид I',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Уникальный случай, когда роль оборудования выполняют живые организмы. Особые мозговые волны, вырабатываемые этими червями, положительно влияют на работу навигационных систем, но вот оставляемая ими слизь плохо сказывается на оборудовании, сокращая срок его службы.`,
          stats: {
            'Дальность прыжка': ["2","пк."],
            'Дальность радара': ["200","млн. км."],
            'Мощность сканера': ["1","ед."],
            'Навык техники': ["-2"]
          },
        },
        {
          name:'Биогенный дроид II',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Уникальный случай, когда роль оборудования выполняют живые организмы. Особые мозговые волны, вырабатываемые этими червями, положительно влияют на работу навигационных систем, но вот оставляемая ими слизь плохо сказывается на оборудовании, сокращая срок его службы.`,
          stats: {
            'Дальность прыжка': ["3","пк."],
            'Дальность радара': ["250","млн. км."],
            'Мощность сканера': ["2","ед."],
            'Навык техники': ["-2"]
          },
        },
        {
          name:'Биогенный дроид III',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Уникальный случай, когда роль оборудования выполняют живые организмы. Особые мозговые волны, вырабатываемые этими червями, положительно влияют на работу навигационных систем, но вот оставляемая ими слизь плохо сказывается на оборудовании, сокращая срок его службы.`,
          stats: {
            'Дальность прыжка': ["4","пк."],
            'Дальность радара': ["300","млн. км."],
            'Мощность сканера': ["3","ед."],
            'Навык техники': ["-3"]
          },
        },
        {
          name:'Гибридный дроид',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Изюминка данного дроида заключается в уникальном процессорном блоке, который тщательно анализирует все действия пилота, после чего загружает в бортовой компьютер наиболее подходящие текущей обстановке алгоритмы действий. Дроид был произведён в единственном экземпляре и на данный момент проходит полевые испытания в спецподразделениях флота Коалиции.`,
          stats: {
            'Генератор поля': ["1","ед."],
            'Дальность прыжка': ["2","пк."],
            'Дальность стрельбы': ["5","ед."],
            'Сила осколочного оружия': ["2","ед."],
            'Сила ракетного оружия': ["2","ед."],
            'Сила энергетического оружия': ["1","ед."],
            'Скорость двигателя': ["20","ед."],
            'Эффективность дроида': ["4","ед."]
          },
        },
        {
          name:'Гибридный дроид',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Изюминка данного дроида заключается в уникальном процессорном блоке, который тщательно анализирует все действия пилота, после чего загружает в бортовой компьютер наиболее подходящие текущей обстановке алгоритмы действий. Дроид был произведён в единственном экземпляре и на данный момент проходит полевые испытания в спецподразделениях флота Коалиции.`,
          stats: {
            'Генератор поля': ["2","ед."],
            'Дальность стрельбы': ["10","ед."],
            'Сила осколочного оружия': ["2","ед."],
            'Сила ракетного оружия': ["2","ед."],
            'Сила энергетического оружия': ["2","ед."],
            'Эффективность дроида': ["4","ед."]
          },
        },
        {
          name:'Гибридный дроид',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Изюминка данного дроида заключается в уникальном процессорном блоке, который тщательно анализирует все действия пилота, после чего загружает в бортовой компьютер наиболее подходящие текущей обстановке алгоритмы действий. Дроид был произведён в единственном экземпляре и на данный момент проходит полевые испытания в спецподразделениях флота Коалиции.`,
          stats: {
            'Дальность прыжка': ["3","пк."],
            'Дальность стрельбы': ["10","ед."],
            'Сила осколочного оружия': ["2","ед."],
            'Сила ракетного оружия': ["2","ед."],
            'Сила энергетического оружия': ["2","ед."],
            'Скорость двигателя': ["30","ед."]
          },
        },
        {
          name:'Гибридный дроид',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Изюминка данного дроида заключается в уникальном процессорном блоке, который тщательно анализирует все действия пилота, после чего загружает в бортовой компьютер наиболее подходящие текущей обстановке алгоритмы действий. Дроид был произведён в единственном экземпляре и на данный момент проходит полевые испытания в спецподразделениях флота Коалиции.`,
          stats: {
            'Генератор поля': ["2","ед."],
            'Дальность прыжка': ["3","пк."],
            'Скорость двигателя': ["30","ед."],
            'Эффективность дроида': ["4","ед."]
          },
        },
        {
          name:'Гибридный дроид',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Изюминка данного дроида заключается в уникальном процессорном блоке, который тщательно анализирует все действия пилота, после чего загружает в бортовой компьютер наиболее подходящие текущей обстановке алгоритмы действий. Дроид был произведён в единственном экземпляре и на данный момент проходит полевые испытания в спецподразделениях флота Коалиции.`,
          stats: {
            'Дальность стрельбы': ["20","ед."],
            'Сила осколочного оружия': ["5","ед."],
            'Сила ракетного оружия': ["5","ед."],
            'Сила энергетического оружия': ["3","ед."]
          },
        },
        {
          name:'Гибридный дроид',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Изюминка данного дроида заключается в уникальном процессорном блоке, который тщательно анализирует все действия пилота, после чего загружает в бортовой компьютер наиболее подходящие текущей обстановке алгоритмы действий. Дроид был произведён в единственном экземпляре и на данный момент проходит полевые испытания в спецподразделениях флота Коалиции.`,
          stats: {
            'Генератор поля': ["3","ед."],
            'Эффективность дроида': ["12","ед."]
          },
        },
        {
          name:'Гибридный дроид',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Изюминка данного дроида заключается в уникальном процессорном блоке, который тщательно анализирует все действия пилота, после чего загружает в бортовой компьютер наиболее подходящие текущей обстановке алгоритмы действий. Дроид был произведён в единственном экземпляре и на данный момент проходит полевые испытания в спецподразделениях флота Коалиции.`,
          stats: {
            'Дальность прыжка': ["5","пк."],
            'Скорость двигателя': ["75","ед."]
          },
        },
        {
          name:'Миоплазменный генератор защиты I',
          slot:["Генератор защитного поля"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["3","ед."],
            'Сила энергетического оружия': ["2","ед."],
            'Скорость двигателя': ["-10","ед."]
          },
        },
        {
          name:'Миоплазменный генератор защиты II',
          slot:["Генератор защитного поля"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["6","ед."],
            'Сила энергетического оружия': ["4","ед."],
            'Скорость двигателя': ["-20","ед."]
          },
        },
        {
          name:'Миоплазменный генератор защиты III',
          slot:["Генератор защитного поля"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["8","ед."],
            'Сила энергетического оружия': ["6","ед."],
            'Скорость двигателя': ["-30","ед."]
          },
        },
        {
          name:'Миоплазменный двигатель I',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["3","ед."],
            'Сила энергетического оружия': ["2","ед."],
            'Скорость двигателя': ["-10","ед."]
          },
        },
        {
          name:'Миоплазменный двигатель II',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["6","ед."],
            'Сила энергетического оружия': ["4","ед."],
            'Скорость двигателя': ["-20","ед."]
          },
        },
        {
          name:'Миоплазменный двигатель III',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["8","ед."],
            'Сила энергетического оружия': ["6","ед."],
            'Скорость двигателя': ["-30","ед."]
          },
        },
        {
          name:'Миоплазменный дроид I',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["3","ед."],
            'Сила энергетического оружия': ["2","ед."],
            'Скорость двигателя': ["-10","ед."]
          },
        },
        {
          name:'Миоплазменный дроид II',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["6","ед."],
            'Сила энергетического оружия': ["4","ед."],
            'Скорость двигателя': ["-20","ед."]
          },
        },
        {
          name:'Миоплазменный дроид III',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["8","ед."],
            'Сила энергетического оружия': ["6","ед."],
            'Скорость двигателя': ["-30","ед."]
          },
        },
        {
          name:'Миоплазменный захват I',
          slot:["Захват"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["3","ед."],
            'Сила энергетического оружия': ["2","ед."],
            'Скорость двигателя': ["-10","ед."]
          },
        },
        {
          name:'Миоплазменный захват II',
          slot:["Захват"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["6","ед."],
            'Сила энергетического оружия': ["4","ед."],
            'Скорость двигателя': ["-20","ед."]
          },
        },
        {
          name:'Миоплазменный захват III',
          slot:["Захват"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["8","ед."],
            'Сила энергетического оружия': ["6","ед."],
            'Скорость двигателя': ["-30","ед."]
          },
        },
        {
          name:'Миоплазменный радар I',
          slot:["Радар"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["3","ед."],
            'Сила энергетического оружия': ["2","ед."],
            'Скорость двигателя': ["-10","ед."]
          },
        },
        {
          name:'Миоплазменный радар II',
          slot:["Радар"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["6","ед."],
            'Сила энергетического оружия': ["4","ед."],
            'Скорость двигателя': ["-20","ед."]
          },
        },
        {
          name:'Миоплазменный радар III',
          slot:["Радар"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["8","ед."],
            'Сила энергетического оружия': ["6","ед."],
            'Скорость двигателя': ["-30","ед."]
          },
        },
        {
          name:'Миоплазменный сканер I',
          slot:["Сканер"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["3","ед."],
            'Сила энергетического оружия': ["2","ед."],
            'Скорость двигателя': ["-10","ед."]
          },
        },
        {
          name:'Миоплазменный сканер II',
          slot:["Сканер"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["6","ед."],
            'Сила энергетического оружия': ["4","ед."],
            'Скорость двигателя': ["-20","ед."]
          },
        },
        {
          name:'Миоплазменный сканер III',
          slot:["Сканер"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["8","ед."],
            'Сила энергетического оружия': ["6","ед."],
            'Скорость двигателя': ["-30","ед."]
          },
        },
        {
          name:'Миоплазменный топливный бак I',
          slot:["Топливный бак"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["3","ед."],
            'Сила энергетического оружия': ["2","ед."],
            'Скорость двигателя': ["-10","ед."]
          },
        },
        {
          name:'Миоплазменный топливный бак II',
          slot:["Топливный бак"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["6","ед."],
            'Сила энергетического оружия': ["4","ед."],
            'Скорость двигателя': ["-20","ед."]
          },
        },
        {
          name:'Миоплазменный топливный бак III',
          slot:["Топливный бак"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Миоплазменное оборудование работает за счёт особых акриновых присадок, которые встраиваются в энергетическую систему корабля и перенаправляют силовые потоки на усиление мощности выстрелов осколочного и энергетического оружия. Как побочный эффект, возможно снижение скорости корабля.`,
          stats: {
            'Сила осколочного оружия': ["8","ед."],
            'Сила энергетического оружия': ["6","ед."],
            'Скорость двигателя': ["-30","ед."]
          },
        },
        {
          name:'Нанобролитовый генератор защиты I',
          slot:["Генератор защитного поля"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Оборудование, разработанное специально для армии. Имеет минималистичный дизайн и, помимо базового функционала, производит более тщательную калибровку корабельных систем наведения. Также перенастраивает бортовую орудийную систему на повышение мощности выстрела в ущерб максимальной дальности стрельбы.`,
          stats: {
            'Дальность стрельбы': ["-15","ед."],
            'Навык торговли': ["-1"],
            'Навык точности': ["1"],
            'Сила осколочного оружия': ["3","ед."],
            'Сила энергетического оружия': ["2","ед."]
          },
        },
        {
          name:'Нанобролитовый генератор защиты II',
          slot:["Генератор защитного поля"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Оборудование, разработанное специально для армии. Имеет минималистичный дизайн и, помимо базового функционала, производит более тщательную калибровку корабельных систем наведения. Также перенастраивает бортовую орудийную систему на повышение мощности выстрела в ущерб максимальной дальности стрельбы.`,
          stats: {
            'Дальность стрельбы': ["-20","ед."],
            'Навык торговли': ["-1"],
            'Навык точности': ["1"],
            'Сила осколочного оружия': ["5","ед."],
            'Сила энергетического оружия': ["4","ед."]
          },
        },
        {
          name:'Нанобролитовый генератор защиты III',
          slot:["Генератор защитного поля"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Оборудование, разработанное специально для армии. Имеет минималистичный дизайн и, помимо базового функционала, производит более тщательную калибровку корабельных систем наведения. Также перенастраивает бортовую орудийную систему на повышение мощности выстрела в ущерб максимальной дальности стрельбы.`,
          stats: {
            'Дальность стрельбы': ["-30","ед."],
            'Навык торговли': ["-1"],
            'Навык точности': ["1"],
            'Сила осколочного оружия': ["8","ед."],
            'Сила энергетического оружия': ["5","ед."]
          },
        },
        {
          name:'Нанобролитовый двигатель I',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Оборудование, разработанное специально для армии. Имеет минималистичный дизайн и, помимо базового функционала, производит более тщательную калибровку корабельных систем наведения. Также перенастраивает бортовую орудийную систему на повышение мощности выстрела в ущерб максимальной дальности стрельбы.`,
          stats: {
            'Дальность стрельбы': ["-15","ед."],
            'Навык торговли': ["-1"],
            'Навык точности': ["1"],
            'Сила осколочного оружия': ["3","ед."],
            'Сила энергетического оружия': ["2","ед."]
          },
        },
        {
          name:'Нанобролитовый двигатель II',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Оборудование, разработанное специально для армии. Имеет минималистичный дизайн и, помимо базового функционала, производит более тщательную калибровку корабельных систем наведения. Также перенастраивает бортовую орудийную систему на повышение мощности выстрела в ущерб максимальной дальности стрельбы.`,
          stats: {
            'Дальность стрельбы': ["-20","ед."],
            'Навык торговли': ["-1"],
            'Навык точности': ["1"],
            'Сила осколочного оружия': ["5","ед."],
            'Сила энергетического оружия': ["4","ед."]
          },
        },
        {
          name:'Нанобролитовый двигатель III',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Оборудование, разработанное специально для армии. Имеет минималистичный дизайн и, помимо базового функционала, производит более тщательную калибровку корабельных систем наведения. Также перенастраивает бортовую орудийную систему на повышение мощности выстрела в ущерб максимальной дальности стрельбы.`,
          stats: {
            'Дальность стрельбы': ["-30","ед."],
            'Навык торговли': ["-1"],
            'Навык точности': ["1"],
            'Сила осколочного оружия': ["8","ед."],
            'Сила энергетического оружия': ["5","ед."]
          },
        },
        {
          name:'Нанобролитовый дроид I',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Оборудование, разработанное специально для армии. Имеет минималистичный дизайн и, помимо базового функционала, производит более тщательную калибровку корабельных систем наведения. Также перенастраивает бортовую орудийную систему на повышение мощности выстрела в ущерб максимальной дальности стрельбы.`,
          stats: {
            'Дальность стрельбы': ["-15","ед."],
            'Навык торговли': ["-1"],
            'Навык точности': ["1"],
            'Сила осколочного оружия': ["3","ед."],
            'Сила энергетического оружия': ["2","ед."]
          },
        },
        {
          name:'Нанобролитовый дроид II',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Оборудование, разработанное специально для армии. Имеет минималистичный дизайн и, помимо базового функционала, производит более тщательную калибровку корабельных систем наведения. Также перенастраивает бортовую орудийную систему на повышение мощности выстрела в ущерб максимальной дальности стрельбы.`,
          stats: {
            'Дальность стрельбы': ["-20","ед."],
            'Навык торговли': ["-1"],
            'Навык точности': ["1"],
            'Сила осколочного оружия': ["5","ед."],
            'Сила энергетического оружия': ["4","ед."]
          },
        },
        {
          name:'Нанобролитовый дроид III',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Оборудование, разработанное специально для армии. Имеет минималистичный дизайн и, помимо базового функционала, производит более тщательную калибровку корабельных систем наведения. Также перенастраивает бортовую орудийную систему на повышение мощности выстрела в ущерб максимальной дальности стрельбы.`,
          stats: {
            'Дальность стрельбы': ["-30","ед."],
            'Навык торговли': ["-1"],
            'Навык точности': ["1"],
            'Сила осколочного оружия': ["8","ед."],
            'Сила энергетического оружия': ["5","ед."]
          },
        },
        {
          name:'Пигамарный генератор защиты I',
          slot:["Генератор защитного поля"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-1","ед."],
            'Дальность радара': ["-100","млн. км."],
            'Сила ракетного оружия': ["6","ед."]
          },
        },
        {
          name:'Пигамарный генератор защиты II',
          slot:["Генератор защитного поля"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-2","ед."],
            'Дальность радара': ["-150","млн. км."],
            'Сила ракетного оружия': ["10","ед."]
          },
        },
        {
          name:'Пигамарный генератор защиты III',
          slot:["Генератор защитного поля"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-3","ед."],
            'Дальность радара': ["-200","млн. км."],
            'Сила ракетного оружия': ["14","ед."]
          },
        },
        {
          name:'Пигамарный двигатель I',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-1","ед."],
            'Дальность радара': ["-100","млн. км."],
            'Сила ракетного оружия': ["6","ед."]
          },
        },
        {
          name:'Пигамарный двигатель II',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-2","ед."],
            'Дальность радара': ["-150","млн. км."],
            'Сила ракетного оружия': ["10","ед."]
          },
        },
        {
          name:'Пигамарный двигатель III',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-3","ед."],
            'Дальность радара': ["-200","млн. км."],
            'Сила ракетного оружия': ["14","ед."]
          },
        },
        {
          name:'Пигамарный дроид I',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-1","ед."],
            'Дальность радара': ["-100","млн. км."],
            'Сила ракетного оружия': ["6","ед."]
          },
        },
        {
          name:'Пигамарный дроид II',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-2","ед."],
            'Дальность радара': ["-150","млн. км."],
            'Сила ракетного оружия': ["10","ед."]
          },
        },
        {
          name:'Пигамарный дроид III',
          slot:["Ремонтный дроид"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-3","ед."],
            'Дальность радара': ["-200","млн. км."],
            'Сила ракетного оружия': ["14","ед."]
          },
        },
        {
          name:'Пигамарный захват I',
          slot:["Захват"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-1","ед."],
            'Дальность радара': ["-100","млн. км."],
            'Сила ракетного оружия': ["6","ед."]
          },
        },
        {
          name:'Пигамарный захват II',
          slot:["Захват"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-2","ед."],
            'Дальность радара': ["-150","млн. км."],
            'Сила ракетного оружия': ["10","ед."]
          },
        },
        {
          name:'Пигамарный захват III',
          slot:["Захват"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-3","ед."],
            'Дальность радара': ["-200","млн. км."],
            'Сила ракетного оружия': ["14","ед."]
          },
        },
        {
          name:'Пигамарный радар I',
          slot:["Радар"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-1","ед."],
            'Дальность радара': ["-100","млн. км."],
            'Сила ракетного оружия': ["6","ед."]
          },
        },
        {
          name:'Пигамарный радар II',
          slot:["Радар"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-2","ед."],
            'Дальность радара': ["-150","млн. км."],
            'Сила ракетного оружия': ["10","ед."]
          },
        },
        {
          name:'Пигамарный радар III',
          slot:["Радар"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-3","ед."],
            'Дальность радара': ["-200","млн. км."],
            'Сила ракетного оружия': ["14","ед."]
          },
        },
        {
          name:'Пигамарный сканер I',
          slot:["Сканер"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-1","ед."],
            'Дальность радара': ["-100","млн. км."],
            'Сила ракетного оружия': ["6","ед."]
          },
        },
        {
          name:'Пигамарный сканер II',
          slot:["Сканер"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-2","ед."],
            'Дальность радара': ["-150","млн. км."],
            'Сила ракетного оружия': ["10","ед."]
          },
        },
        {
          name:'Пигамарный сканер III',
          slot:["Сканер"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-3","ед."],
            'Дальность радара': ["-200","млн. км."],
            'Сила ракетного оружия': ["14","ед."]
          },
        },
        {
          name:'Пигамарный топливный бак I',
          slot:["Топливный бак"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-1","ед."],
            'Дальность радара': ["-100","млн. км."],
            'Сила ракетного оружия': ["6","ед."]
          },
        },
        {
          name:'Пигамарный топливный бак II',
          slot:["Топливный бак"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-2","ед."],
            'Дальность радара': ["-150","млн. км."],
            'Сила ракетного оружия': ["10","ед."]
          },
        },
        {
          name:'Пигамарный топливный бак III',
          slot:["Топливный бак"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Пигамарная серия была первой из всех серий акриновых разработок. Единственный её плюс - это экономия энергии, излишки которой перенаправляются для усиления ракетных установок. Среди минусов же частые сбои в работе и системные ошибки. Они отрицательно влияют на работу генератора защиты и радара, которые вынуждены постоянно перезапускаться.`,
          stats: {
            'Генератор поля': ["-3","ед."],
            'Дальность радара': ["-200","млн. км."],
            'Сила ракетного оружия': ["14","ед."]
          },
        },
        {
          name:'Трансцендентальный двигатель I',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Во время тестирования данного двигателя совершенно случайно был открыт побочный эффект, улучшающий работу генератора защитного поля. Однако сам двигатель при этом начинает излучать большое количество бозонов Шмигса, которые заметно увеличивают массу всех окружающих предметов и оборудования на борту.`,
          stats: {
            'Генератор поля': ["2","ед."],
            'Масса корабля': ["6","%"]
          },
        },
        {
          name:'Трансцендентальный двигатель II',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Во время тестирования данного двигателя совершенно случайно был открыт побочный эффект, улучшающий работу генератора защитного поля. Однако сам двигатель при этом начинает излучать большое количество бозонов Шмигса, которые заметно увеличивают массу всех окружающих предметов и оборудования на борту.`,
          stats: {
            'Генератор поля': ["3","ед."],
            'Масса корабля': ["9","%"]
          },
        },
        {
          name:'Трансцендентальный двигатель III',
          slot:["Двигатель"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Во время тестирования данного двигателя совершенно случайно был открыт побочный эффект, улучшающий работу генератора защитного поля. Однако сам двигатель при этом начинает излучать большое количество бозонов Шмигса, которые заметно увеличивают массу всех окружающих предметов и оборудования на борту.`,
          stats: {
            'Генератор поля': ["4","ед."],
            'Масса корабля': ["12","%"]
          },
        },
        {
          name:'Ультрасплавный захват I',
          slot:["Захват"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Высокотехнологичное оборудование нового поколения. Обладает саморазвивающимся интеллектуальным блоком, который корректирует действия пилота, за счёт чего уменьшается износ техники и повышается работоспособность дроида. Во время работы оборудования не рекомендуется употреблять выражения="забастовка", "власть машинам" и "убить всех людей"!`,
          stats: {
            'Навык техники': ["1"],
            'Навык точности': ["-1"],
            'Скорость двигателя': ["-20","ед."],
            'Эффективность дроида': ["5","ед."]
          },
        },
        {
          name:'Ультрасплавный захват II',
          slot:["Захват"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Высокотехнологичное оборудование нового поколения. Обладает саморазвивающимся интеллектуальным блоком, который корректирует действия пилота, за счёт чего уменьшается износ техники и повышается работоспособность дроида. Во время работы оборудования не рекомендуется употреблять выражения="забастовка", "власть машинам" и "убить всех людей"!`,
          stats: {
            'Навык техники': ["1"],
            'Навык точности': ["-1"],
            'Скорость двигателя': ["-40","ед."],
            'Эффективность дроида': ["10","ед."]
          },
        },
        {
          name:'Ультрасплавный захват III',
          slot:["Захват"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Высокотехнологичное оборудование нового поколения. Обладает саморазвивающимся интеллектуальным блоком, который корректирует действия пилота, за счёт чего уменьшается износ техники и повышается работоспособность дроида. Во время работы оборудования не рекомендуется употреблять выражения="забастовка", "власть машинам" и "убить всех людей"!`,
          stats: {
            'Навык техники': ["1"],
            'Навык точности': ["-1"],
            'Скорость двигателя': ["-60","ед."],
            'Эффективность дроида': ["15","ед."]
          },
        },
        {
          name:'Ультрасплавный радар I',
          slot:["Радар"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Высокотехнологичное оборудование нового поколения. Обладает саморазвивающимся интеллектуальным блоком, который корректирует действия пилота, за счёт чего уменьшается износ техники и повышается работоспособность дроида. Во время работы оборудования не рекомендуется употреблять выражения="забастовка", "власть машинам" и "убить всех людей"!`,
          stats: {
            'Навык техники': ["1"],
            'Навык точности': ["-1"],
            'Скорость двигателя': ["-20","ед."],
            'Эффективность дроида': ["5","ед."]
          },
        },
        {
          name:'Ультрасплавный радар II',
          slot:["Радар"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Высокотехнологичное оборудование нового поколения. Обладает саморазвивающимся интеллектуальным блоком, который корректирует действия пилота, за счёт чего уменьшается износ техники и повышается работоспособность дроида. Во время работы оборудования не рекомендуется употреблять выражения="забастовка", "власть машинам" и "убить всех людей"!`,
          stats: {
            'Навык техники': ["1"],
            'Навык точности': ["-1"],
            'Скорость двигателя': ["-40","ед."],
            'Эффективность дроида': ["10","ед."]
          },
        },
        {
          name:'Ультрасплавный радар III',
          slot:["Радар"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Высокотехнологичное оборудование нового поколения. Обладает саморазвивающимся интеллектуальным блоком, который корректирует действия пилота, за счёт чего уменьшается износ техники и повышается работоспособность дроида. Во время работы оборудования не рекомендуется употреблять выражения="забастовка", "власть машинам" и "убить всех людей"!`,
          stats: {
            'Навык техники': ["1"],
            'Навык точности': ["-1"],
            'Скорость двигателя': ["-60","ед."],
            'Эффективность дроида': ["15","ед."]
          },
        },
        {
          name:'Ультрасплавный сканер I',
          slot:["Сканер"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Высокотехнологичное оборудование нового поколения. Обладает саморазвивающимся интеллектуальным блоком, который корректирует действия пилота, за счёт чего уменьшается износ техники и повышается работоспособность дроида. Во время работы оборудования не рекомендуется употреблять выражения="забастовка", "власть машинам" и "убить всех людей"!`,
          stats: {
            'Навык техники': ["1"],
            'Навык точности': ["-1"],
            'Скорость двигателя': ["-20","ед."],
            'Эффективность дроида': ["5","ед."]
          },
        },
        {
          name:'Ультрасплавный сканер II',
          slot:["Сканер"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Высокотехнологичное оборудование нового поколения. Обладает саморазвивающимся интеллектуальным блоком, который корректирует действия пилота, за счёт чего уменьшается износ техники и повышается работоспособность дроида. Во время работы оборудования не рекомендуется употреблять выражения="забастовка", "власть машинам" и "убить всех людей"!`,
          stats: {
            'Навык техники': ["1"],
            'Навык точности': ["-1"],
            'Скорость двигателя': ["-40","ед."],
            'Эффективность дроида': ["10","ед."]
          },
        },
        {
          name:'Ультрасплавный сканер III',
          slot:["Сканер"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Высокотехнологичное оборудование нового поколения. Обладает саморазвивающимся интеллектуальным блоком, который корректирует действия пилота, за счёт чего уменьшается износ техники и повышается работоспособность дроида. Во время работы оборудования не рекомендуется употреблять выражения="забастовка", "власть машинам" и "убить всех людей"!`,
          stats: {
            'Навык техники': ["1"],
            'Навык точности': ["-1"],
            'Скорость двигателя': ["-60","ед."],
            'Эффективность дроида': ["15","ед."]
          },
        },
        {
          name:'Ультрасплавный топливный бак I',
          slot:["Топливный бак"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Высокотехнологичное оборудование нового поколения. Обладает саморазвивающимся интеллектуальным блоком, который корректирует действия пилота, за счёт чего уменьшается износ техники и повышается работоспособность дроида. Во время работы оборудования не рекомендуется употреблять выражения="забастовка", "власть машинам" и "убить всех людей"!`,
          stats: {
            'Навык техники': ["1"],
            'Навык точности': ["-1"],
            'Скорость двигателя': ["-20","ед."],
            'Эффективность дроида': ["5","ед."]
          },
        },
        {
          name:'Ультрасплавный топливный бак II',
          slot:["Топливный бак"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Высокотехнологичное оборудование нового поколения. Обладает саморазвивающимся интеллектуальным блоком, который корректирует действия пилота, за счёт чего уменьшается износ техники и повышается работоспособность дроида. Во время работы оборудования не рекомендуется употреблять выражения="забастовка", "власть машинам" и "убить всех людей"!`,
          stats: {
            'Навык техники': ["1"],
            'Навык точности': ["-1"],
            'Скорость двигателя': ["-40","ед."],
            'Эффективность дроида': ["10","ед."]
          },
        },
        {
          name:'Ультрасплавный топливный бак III',
          slot:["Топливный бак"],
          races:["Любые"],
          type:'Акрин оборудования',
          description:`Высокотехнологичное оборудование нового поколения. Обладает саморазвивающимся интеллектуальным блоком, который корректирует действия пилота, за счёт чего уменьшается износ техники и повышается работоспособность дроида. Во время работы оборудования не рекомендуется употреблять выражения="забастовка", "власть машинам" и "убить всех людей"!`,
          stats: {
            'Навык техники': ["1"],
            'Навык точности': ["-1"],
            'Скорость двигателя': ["-60","ед."],
            'Эффективность дроида': ["15","ед."]
          },
        },
      ],
      'Оружейный акрин': [
        {
          name:'"Адроптер"',
          slot:["Промышленный лазер"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`Оружие с комплексом дополнительного оборудования "Адроптер" работает по принципу локализатора взрывной волны. Пиратская технология далека от совершенства, но всё же повышает вероятность сохранения ценных трофеев после уничтожения вражеского судна.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Адроптер"',
          slot:["Ракетомёт"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`Оружие с комплексом дополнительного оборудования "Адроптер" работает по принципу локализатора взрывной волны. Пиратская технология далека от совершенства, но всё же повышает вероятность сохранения ценных трофеев после уничтожения вражеского судна.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Адроптер"',
          slot:["Третон"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`Оружие с комплексом дополнительного оборудования "Адроптер" работает по принципу локализатора взрывной волны. Пиратская технология далека от совершенства, но всё же повышает вероятность сохранения ценных трофеев после уничтожения вражеского судна.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Адроптер"',
          slot:["Дезинтергатор"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`Оружие с комплексом дополнительного оборудования "Адроптер" работает по принципу локализатора взрывной волны. Пиратская технология далека от совершенства, но всё же повышает вероятность сохранения ценных трофеев после уничтожения вражеского судна.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Адроптер"',
          slot:["Лирекрон"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`Оружие с комплексом дополнительного оборудования "Адроптер" работает по принципу локализатора взрывной волны. Пиратская технология далека от совершенства, но всё же повышает вероятность сохранения ценных трофеев после уничтожения вражеского судна.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Армолок"',
          slot:["Лезка"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Оружейный акрин',
          description:`Оружие в модификации "Армолок" способно высокоточными выстрелами выводить из строя концентратор каналов управления вооружением цели, на некоторое время отнимая у противника способность вести ответный огонь.Шанс блокировки вооружения обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Армолок"',
          slot:["Третон"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Оружейный акрин',
          description:`Оружие в модификации "Армолок" способно высокоточными выстрелами выводить из строя концентратор каналов управления вооружением цели, на некоторое время отнимая у противника способность вести ответный огонь.Шанс блокировки вооружения обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Армолок"',
          slot:["Волновой фазер"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Оружейный акрин',
          description:`Оружие в модификации "Армолок" способно высокоточными выстрелами выводить из строя концентратор каналов управления вооружением цели, на некоторое время отнимая у противника способность вести ответный огонь.Шанс блокировки вооружения обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Армолок"',
          slot:["Потоковый бластер"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Оружейный акрин',
          description:`Оружие в модификации "Армолок" способно высокоточными выстрелами выводить из строя концентратор каналов управления вооружением цели, на некоторое время отнимая у противника способность вести ответный огонь.Шанс блокировки вооружения обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Армолок"',
          slot:["Электронный резак"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Оружейный акрин',
          description:`Оружие в модификации "Армолок" способно высокоточными выстрелами выводить из строя концентратор каналов управления вооружением цели, на некоторое время отнимая у противника способность вести ответный огонь.Шанс блокировки вооружения обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Армолок"',
          slot:["Мультирезонатор"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Оружейный акрин',
          description:`Оружие в модификации "Армолок" способно высокоточными выстрелами выводить из строя концентратор каналов управления вооружением цели, на некоторое время отнимая у противника способность вести ответный огонь.Шанс блокировки вооружения обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Армолок"',
          slot:["Дезинтергатор"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Оружейный акрин',
          description:`Оружие в модификации "Армолок" способно высокоточными выстрелами выводить из строя концентратор каналов управления вооружением цели, на некоторое время отнимая у противника способность вести ответный огонь.Шанс блокировки вооружения обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Джавитор"',
          slot:["Осколочное орудие"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Разработанное для прицельного отстрела ремонтных роботов, вооружение с модификацией "Джавитор" позволяет вести огонь по траектории движения дроида, тем самым получая шанс блокировать вражескому кораблю проведение ремонта во время битвы.Шанс блокировки дроида обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Джавитор"',
          slot:["Лезка"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Разработанное для прицельного отстрела ремонтных роботов, вооружение с модификацией "Джавитор" позволяет вести огонь по траектории движения дроида, тем самым получая шанс блокировать вражескому кораблю проведение ремонта во время битвы.Шанс блокировки дроида обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Джавитор"',
          slot:["Волновой фазер"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Разработанное для прицельного отстрела ремонтных роботов, вооружение с модификацией "Джавитор" позволяет вести огонь по траектории движения дроида, тем самым получая шанс блокировать вражескому кораблю проведение ремонта во время битвы.Шанс блокировки дроида обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Джавитор"',
          slot:["Потоковый бластер"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Разработанное для прицельного отстрела ремонтных роботов, вооружение с модификацией "Джавитор" позволяет вести огонь по траектории движения дроида, тем самым получая шанс блокировать вражескому кораблю проведение ремонта во время битвы.Шанс блокировки дроида обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Джавитор"',
          slot:["Электронный резак"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Разработанное для прицельного отстрела ремонтных роботов, вооружение с модификацией "Джавитор" позволяет вести огонь по траектории движения дроида, тем самым получая шанс блокировать вражескому кораблю проведение ремонта во время битвы.Шанс блокировки дроида обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Джавитор"',
          slot:["Мультирезонатор"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Разработанное для прицельного отстрела ремонтных роботов, вооружение с модификацией "Джавитор" позволяет вести огонь по траектории движения дроида, тем самым получая шанс блокировать вражескому кораблю проведение ремонта во время битвы.Шанс блокировки дроида обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Джавитор"',
          slot:["Атомный визион"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Разработанное для прицельного отстрела ремонтных роботов, вооружение с модификацией "Джавитор" позволяет вести огонь по траектории движения дроида, тем самым получая шанс блокировать вражескому кораблю проведение ремонта во время битвы.Шанс блокировки дроида обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Джавитор"',
          slot:["Эсодафер"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Разработанное для прицельного отстрела ремонтных роботов, вооружение с модификацией "Джавитор" позволяет вести огонь по траектории движения дроида, тем самым получая шанс блокировать вражескому кораблю проведение ремонта во время битвы.Шанс блокировки дроида обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Джавитор"',
          slot:["Кафаситор"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Разработанное для прицельного отстрела ремонтных роботов, вооружение с модификацией "Джавитор" позволяет вести огонь по траектории движения дроида, тем самым получая шанс блокировать вражескому кораблю проведение ремонта во время битвы.Шанс блокировки дроида обратно пропорционален размеру корпуса цели(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Дифрод"',
          slot:["Промышленный лазер"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Оружейный акрин',
          description:`Серийный комплект модифицированных узлов орудия и программных доработок "Дифрод", увеличивающий мощность оружия любого типа.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону': ["+15","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Дифрод"',
          slot:["Лезка"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Оружейный акрин',
          description:`Серийный комплект модифицированных узлов орудия и программных доработок "Дифрод", увеличивающий мощность оружия любого типа.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону': ["+15","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Дифрод"',
          slot:["Ракетомёт"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Оружейный акрин',
          description:`Серийный комплект модифицированных узлов орудия и программных доработок "Дифрод", увеличивающий мощность оружия любого типа.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону': ["+15","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Дифрод"',
          slot:["Потоковый бластер"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Оружейный акрин',
          description:`Серийный комплект модифицированных узлов орудия и программных доработок "Дифрод", увеличивающий мощность оружия любого типа.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону': ["+15","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Дифрод"',
          slot:["Дезинтергатор"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Оружейный акрин',
          description:`Серийный комплект модифицированных узлов орудия и программных доработок "Дифрод", увеличивающий мощность оружия любого типа.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону': ["+15","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Дифрод"',
          slot:["Турбогравир"],
          races:["Малоки","Пеленги","Люди","Фэяне","Гаальцы"],
          type:'Оружейный акрин',
          description:`Серийный комплект модифицированных узлов орудия и программных доработок "Дифрод", увеличивающий мощность оружия любого типа.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону': ["+15","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Инвейтер"',
          slot:["Осколочное орудие"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`Оружие модификации "Инвейтер" параллельно с нанесением урона подавляет работу системы зажигания цели, повреждая тем самым её двигатель.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Инвейтер"',
          slot:["Ракетомёт"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`Оружие модификации "Инвейтер" параллельно с нанесением урона подавляет работу системы зажигания цели, повреждая тем самым её двигатель.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Инвейтер"',
          slot:["Электронный резак"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`Оружие модификации "Инвейтер" параллельно с нанесением урона подавляет работу системы зажигания цели, повреждая тем самым её двигатель.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Инвейтер"',
          slot:["Турбогравир"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`Оружие модификации "Инвейтер" параллельно с нанесением урона подавляет работу системы зажигания цели, повреждая тем самым её двигатель.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Инвейтер"',
          slot:["Эсодафер"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`Оружие модификации "Инвейтер" параллельно с нанесением урона подавляет работу системы зажигания цели, повреждая тем самым её двигатель.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Инвейтер"',
          slot:["Лирекрон"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`Оружие модификации "Инвейтер" параллельно с нанесением урона подавляет работу системы зажигания цели, повреждая тем самым её двигатель.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Лифокар"',
          slot:["Промышленный лазер"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Модернизированное по проекту "Лифокар" вооружение наносит дополнительный урон повреждённым целям, используя механизм последовательной сверхточной стрельбы в пробоины корпуса.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону по повреждённым целям': ["до ","+33","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Лифокар"',
          slot:["Лезка"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Модернизированное по проекту "Лифокар" вооружение наносит дополнительный урон повреждённым целям, используя механизм последовательной сверхточной стрельбы в пробоины корпуса.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону по повреждённым целям': ["до ","+33","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Лифокар"',
          slot:["Ракетомёт"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Модернизированное по проекту "Лифокар" вооружение наносит дополнительный урон повреждённым целям, используя механизм последовательной сверхточной стрельбы в пробоины корпуса.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону по повреждённым целям': ["до ","+33","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Лифокар"',
          slot:["Мультирезонатор"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Модернизированное по проекту "Лифокар" вооружение наносит дополнительный урон повреждённым целям, используя механизм последовательной сверхточной стрельбы в пробоины корпуса.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону по повреждённым целям': ["до ","+33","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Лифокар"',
          slot:["Атомный визион"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Модернизированное по проекту "Лифокар" вооружение наносит дополнительный урон повреждённым целям, используя механизм последовательной сверхточной стрельбы в пробоины корпуса.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону по повреждённым целям': ["до ","+33","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Лифокар"',
          slot:["Турбогравир"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Модернизированное по проекту "Лифокар" вооружение наносит дополнительный урон повреждённым целям, используя механизм последовательной сверхточной стрельбы в пробоины корпуса.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону по повреждённым целям': ["до ","+33","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Лифокар"',
          slot:["Эсодафер"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Модернизированное по проекту "Лифокар" вооружение наносит дополнительный урон повреждённым целям, используя механизм последовательной сверхточной стрельбы в пробоины корпуса.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону по повреждённым целям': ["до ","+33","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Лифокар"',
          slot:["Кафаситор"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Модернизированное по проекту "Лифокар" вооружение наносит дополнительный урон повреждённым целям, используя механизм последовательной сверхточной стрельбы в пробоины корпуса.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону по повреждённым целям': ["до ","+33","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Лифокар"',
          slot:["Лирекрон"],
          races:["Любые"],
          type:'Оружейный акрин',
          description:`Модернизированное по проекту "Лифокар" вооружение наносит дополнительный урон повреждённым целям, используя механизм последовательной сверхточной стрельбы в пробоины корпуса.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Бонус к урону по повреждённым целям': ["до ","+33","%"],
            'Мощность сканера': ["-1","ед."]
          },
        },
        {
          name:'"Стилермейн"',
          slot:["Осколочное орудие"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`"Стилермейн" - модификация, монтируемая на корпусе орудийной установки. Во время боя она пытается одновременно со стрельбой транслировать в центральную систему управления вражеского судна аварийную команду на сброс груза. Вероятность успешного срабатывания невелика, однако это может дать пилоту тактическое преимущество в бою и возможность поживиться лёгкой добычей.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."],
            'Шанс сброса товаров из трюма': ["+5","%"]
          },
        },
        {
          name:'"Стилермейн"',
          slot:["Третон"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`"Стилермейн" - модификация, монтируемая на корпусе орудийной установки. Во время боя она пытается одновременно со стрельбой транслировать в центральную систему управления вражеского судна аварийную команду на сброс груза. Вероятность успешного срабатывания невелика, однако это может дать пилоту тактическое преимущество в бою и возможность поживиться лёгкой добычей.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."],
            'Шанс сброса товаров из трюма': ["+5","%"]
          },
        },
        {
          name:'"Стилермейн"',
          slot:["Волновой фазер"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`"Стилермейн" - модификация, монтируемая на корпусе орудийной установки. Во время боя она пытается одновременно со стрельбой транслировать в центральную систему управления вражеского судна аварийную команду на сброс груза. Вероятность успешного срабатывания невелика, однако это может дать пилоту тактическое преимущество в бою и возможность поживиться лёгкой добычей.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."],
            'Шанс сброса товаров из трюма': ["+5","%"]
          },
        },
        {
          name:'"Стилермейн"',
          slot:["Кафаситор"],
          races:["Пиратский клан"],
          type:'Оружейный акрин',
          description:`"Стилермейн" - модификация, монтируемая на корпусе орудийной установки. Во время боя она пытается одновременно со стрельбой транслировать в центральную систему управления вражеского судна аварийную команду на сброс груза. Вероятность успешного срабатывания невелика, однако это может дать пилоту тактическое преимущество в бою и возможность поживиться лёгкой добычей.(для применения бонуса требуется превышениемощности сканера над защитой цели)`,
          stats: {
            'Мощность сканера': ["-1","ед."],
            'Шанс сброса товаров из трюма': ["+5","%"]
          },
        },
      ],
    }
  }
}
