<template>
<label class='i-lab'>{{label}}
  <input @click="sendItem('pick', $event.target)" @mouseenter="sendItem('hover')" class='item' type='radio' :name='name' :value='value' :checked='checked' />
</label>
</template>

<script>
export default {
name: 's-item',
props: {
  checked: Boolean,
  index: Number,
  label: String,
  name: String,
  value: String
},
methods: {
  sendItem(e, t){
    if(e === 'hover' && this.index === undefined) return;
    //console.log('[SEND item]', [{index:this.index, name:this.name, item:this.value}]);
    //const el = e.closest('.select');
    //console.log(el)
    
    this.$emit('getItem', {type:e, data:[{index:this.index, name:this.name, item:this.value}]});
    if(e === 'pick') t.focus();
  }
}
}
</script>

<style>
</style>
