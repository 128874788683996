export const db = {
  'ShusRangers': {
    'ShuMercs': {
      "Двигатель": [
        {
          name:'Корпус "Мерценарий" I',
          slot:["Двигатель"],
          type:"Двигатель",
          description:`Древний корпус, чья технологическая начинка была полностью переоборудована под нужды современных наёмных спецподразделений. Помимо сниженной восприимчивости к урону, корпус имеет встроенные аналитические модули, запатентованные крупнейшими наёмническими группировками, использование которых контролируется по их приватным каналам.`,
          stats: {
            'Дальность захвата': '-10 ед.',
            'Мощность захвата': '-10 ед.',
            'Мощность сканера': '-6 ед.',
            'Навык лидерства': '1',
            'Навык манёвренности': '1',
            'Навык обаяния': '1',
            'Навык техники': '1',
            'Навык торговли': '1',
            'Навык точности': '1',
            'Скорость захвата': '-2 ед.'
          },
        },
        {
          name:'Корпус "Мерценарий" II',
          slot:["Двигатель"],
          type:"Двигатель",
          description:`Древний корпус, чья технологическая начинка была полностью переоборудована под нужды современных наёмных спецподразделений. Помимо сниженной восприимчивости к урону, корпус имеет встроенные аналитические модули, запатентованные крупнейшими наёмническими группировками, использование которых контролируется по их приватным каналам.`,
          stats: {
            'Дальность захвата': '-10 ед.',
            'Мощность захвата': '-10 ед.',
            'Мощность сканера': '-6 ед.',
            'Навык лидерства': '1',
            'Навык манёвренности': '1',
            'Навык обаяния': '1',
            'Навык техники': '1',
            'Навык торговли': '1',
            'Навык точности': '1',
            'Скорость захвата': '-2 ед.'
          },
        },
        {
          name:'Корпус "Мерценарий" III',
          slot:["Двигатель"],
          type:"Двигатель",
          description:`Древний корпус, чья технологическая начинка была полностью переоборудована под нужды современных наёмных спецподразделений. Помимо сниженной восприимчивости к урону, корпус имеет встроенные аналитические модули, запатентованные крупнейшими наёмническими группировками, использование которых контролируется по их приватным каналам.`,
          stats: {
            'Дальность захвата': '-10 ед.',
            'Мощность захвата': '-10 ед.',
            'Мощность сканера': '-6 ед.',
            'Навык лидерства': '1',
            'Навык манёвренности': '1',
            'Навык обаяния': '1',
            'Навык техники': '1',
            'Навык торговли': '1',
            'Навык точности': '1',
            'Скорость захвата': '-2 ед.'
          },
        },
        {
          name:'Корпус "Мерценарий" IV',
          slot:["Двигатель"],
          type:"Двигатель",
          description:`Древний корпус, чья технологическая начинка была полностью переоборудована под нужды современных наёмных спецподразделений. Помимо сниженной восприимчивости к урону, корпус имеет встроенные аналитические модули, запатентованные крупнейшими наёмническими группировками, использование которых контролируется по их приватным каналам.`,
          stats: {
            'Дальность захвата': '-15 ед.',
            'Мощность захвата': '-15 ед.',
            'Мощность сканера': '-7 ед.',
            'Навык лидерства': '1',
            'Навык манёвренности': '1',
            'Навык обаяния': '1',
            'Навык техники': '1',
            'Навык торговли': '1',
            'Навык точности': '1',
            'Скорость захвата': '-3 ед.'
          },
        },
      ],
    }
  }
}
