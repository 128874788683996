export const stats = {
  'Размер корпуса': {
    'all': '0',
  },
  'Броня корпуса': {
    'all': '',
  },

  'Акриновые бонусы': {
    'all': '',
  },
  'Акриновые штрафы': {
    'all': '',
  },
  'Размер': {
    'all': '',
  },
  'Стоимость': {
    'all': '',
  },
  'Дальность радара': {
    'all': '',
  },

  'Скорость захвата': {
    'all': '',
    // 'Корпус': '',
    // 'Двигатель': '',
    // 'Топливный бак': '',
    // 'Генератор защитного поля': '',
    // 'Ремонтный дроид': '',
    // 'Радар': '',
    // 'Сканер': '',
    // 'Захват': ''
  },
  'Дальность захвата': {
    'all': '',
  },
  'Мощность захвата': {
    'all': '',
  },

  'Мощность сканера': {
    'all': '',
  },

  'Скорость двигателя': {
    'all': '',
  },
  'Дальность прыжка': {
    'all': '',
  },
  'Объём бака': {
    'all': '',
  },

  'Дальность стрельбы': {
    'all': '',
  },
  'Сила осколочного оружия': {
    'all': '',
  },
  'Сила ракетного оружия': {
    'all': '',
  },
  'Сила энергетического оружия': {
    'all': '',
  },

  'Надёжность': {
    'all': '',
  },
  'Эффективность дроида': {
    'all': '',
  },
  'Генератор поля': {
    'all': '',
  },


  'Навык лидерства': {
    'all': '',
  },
  'Навык манёвренности': {
    'all': '',
  },
  'Навык обаяния': {
    'all': '',
  },
  'Навык техники': {
    'all': '',
  },
  'Навык торговли': {
    'all': '',
  },
  'Навык точности': {
    'all': '',
  }
};