<template>
<!-- <img alt="Vue logo" src="https://vuejs.org/images/logo.png"> -->
<!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
<div class='main'>
  <leftPanel v-model:statsV='stats' v-model:savedV='saved' />
  <!-- <rightPanel /> -->
</div>
<bottomPanel v-model:statsV='stats' v-model:savedV='saved' />
<!-- <Uselect :items='list' /> -->
</template>

<script>
import leftPanel from './vue/panels/leftPanel.vue';
import bottomPanel from './vue/panels/bottomPanel.vue';
import {stats} from '../db/stats.js';

export default {
name: 'App',
components: {
  leftPanel,
  bottomPanel
},
mounted(){
  this.load();
},
methods: {
  load(){
    console.log('[Load]');
    const params = new URL(document.location.href).searchParams;
    if(!params);
    if(params.has('saved')){
      console.log('YES');
      this.read();
    }
  },
  read(){
    console.log('[Read]');
    const parsed = new URL(document.location.href).searchParams;
    //console.log(parsed);
    for(let [key, val] of parsed){
      console.log('i', key+': '+val);
      if(key === 'saved') continue;
      const s = `${key}: ${val.split(',')}`;
      const el = val.split(',');
      console.log(s);
      if(el[0] !== 'undefined') document.querySelector(`.select[name='${key}'] .group[name='${el[0]}'] .item[value='${el[1]}']`)?.click();
      else
      document.querySelector(`.select[name='${key}'] .item[value='${el[1]}']`)?.click();
    }
  }
},
data(){
  return {
    stats: stats,
    saved: {}
  }
}
}
</script>

<style>
:root {
  --Корпус: rgb(209 119 119);
  --Двигатель: rgb(109 119 119);
  --ТопливныйБак: rgb(9 119 219);
  --Захват: rgb(200 50 100);
  --РемонтныйДроид: rgb(100 170 100);
  --ГенераторЗащитногоПоля: rgb(200 100 200);

  --f: flex;
  --f1: flex;
}

#app {
font-family: Avenir, Helvetica, Arial, sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
text-align: center;
color: #2c3e50;

display: flex;
flex-direction: column;

.main {
  display: flex;
  gap: 0 10px;
  position: relative;
}
}
</style>
