<template>
<div class='rightPanel'>
  <div class='title'>{{data.title||'Title'}}</div>
  <div class='cont'>
    <div class='slot'><div>Слот:</div>
      <div class='list'>
        <template v-for='slot in data.slot' :key='slot'>
          <img :src='slots[slot]' :title='slot' />
        </template>
      </div>
    </div>
    <div class='races'><div>Расы:</div>
      <div class='list'>
        <template v-for='race in data.races' :key='race'>
          <img :src='races[race]' :title='race' />
        </template>
      </div>
    </div>
    <div class='type'><div>Тип:</div> {{data.type||'Type'}}</div>
  </div>
  <div class='description'>{{data.description||'Description'}}</div>
  <div class='stats'>
    <div v-for='(stat, key) of data.stats' :key='key'>
      <div>{{key}} {{stat.join(' ')}}</div>
    </div>
  </div>
</div>
</template>

<script>
export default {
name: 'rightPanel',
  components: {
  },
  props: {
    data: Object
  },
  methods:{
    change(e, file){
      // if(!e.selectedIndex > 0) return;
      const s = {
        name: e.name,
        index: e.selectedIndex,
        value: e.options[e.selectedIndex]?.value,
        group: e.options[e.selectedIndex]?.parentNode?.label
      }
      console.log(s);
      //console.log(this.db[s.group][s.index]);

      s.group ? this.setter({file:file[s.group][s.index]}) : this.setter({name:s.name});
    }
  },
  data(){
    return {
      races: {
        'Пиратский клан': 'https://i.imgur.com/2EIfQm2.png',
        'Гаальцы': 'https://i.imgur.com/FRABfoV.png',
        'Фэяне': 'https://i.imgur.com/Li6N6S8.png',
        'Люди': 'https://i.imgur.com/rkZldvm.png',
        'Малоки': 'https://i.imgur.com/9630Ui6.png',
        'Пеленги': 'https://i.imgur.com/9dOBJry.png',
        'Доминаторы': 'https://i.imgur.com/SUHs0v7.png',
        'Любые': 'https://i.imgur.com/I02u4HA.png'
      },
      slots: {
        'Корпус': 'https://i.imgur.com/ZKc94wW.png',
        'Генератор защитного поля': 'https://i.imgur.com/H9AgUpb.png',
        'Двигатель': 'https://i.imgur.com/cUXNucj.png',
        'Топливный бак': 'https://i.imgur.com/RzvqywH.png',
        'Захват': 'https://i.imgur.com/HFHSSYo.png',
        'Ремонтный дроид': 'https://i.imgur.com/csomKKq.png',
        'Сканер': 'https://i.imgur.com/O365DcP.png',
        'Радар': 'https://i.imgur.com/4Ii9P6G.png',
        'Осколочное орудие': 'https://i.imgur.com/4aqOpKL.png',
        'Лезка': 'https://i.imgur.com/ii0AGMY.png',
        'Дезинтегратор': 'https://i.imgur.com/0NlUJbT.png',
        'Лирекрон': 'https://i.imgur.com/DQogv4S.png',
        'Потоковый бластер': 'https://i.imgur.com/3iGo6e7.png',
        'Ракетомёт': 'https://i.imgur.com/caoAqto.png',
        'Третон': 'https://i.imgur.com/tTRp3fd.png',
        'Турбогравир': 'https://i.imgur.com/yzjRkEZ.png',
        'Электронный резак': 'https://i.imgur.com/iOeAe5q.png',
        'Эксодафер': 'https://i.imgur.com/FgEKlo4.png',
        'Кафаситор': 'https://i.imgur.com/FgEKlo4.png',
        'X-дефибриллятор': 'https://i.imgur.com/idCupyX.png',
        'Прожектор вихря': 'https://i.imgur.com/VH12ajl.png',
        'Ретрактор': 'https://i.imgur.com/fdOdVJo.png',
        'Пульсган R2021': 'https://i.imgur.com/l4ldcrk.png',
        'Фотонная пушка': 'https://i.imgur.com/jiy8KbW.png',
        'Боевой лазер ТБ-4247': 'https://i.imgur.com/LzV9LPF.png',
        'Аннигилятор поля': 'https://i.imgur.com/DmqSeky.png',
        'Атомный визион': 'https://i.imgur.com/b56wx6O.png',
        'Мультирезонатор': 'https://i.imgur.com/CJ3RTW1.png',
        'Волновой фазер': 'https://i.imgur.com/km4OjR7.png',
        'Промышленный лазер': 'https://i.imgur.com/fTGZnGi.png',
        'Ракетное оружие': 'https://i.imgur.com/dviPRxU.png',
      }
    }
  },
  setup(){
  }
}
</script>

<style>
  .rightPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 50%;
    padding: 10px;
    aspect-ratio: 1/0.5;
    position: absolute;
    right: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: rgb(0 0 0);
    /* background-image: url('https://i.imgur.com/p5nChxZ.png'); */
    -webkit-mask: url('https://i.imgur.com/p5nChxZ.png');
    mask-image: url('https://i.imgur.com/p5nChxZ.png');
    mask-size: 100% 100%;
    /* filter: drop-shadow(17px 13px 7px rgba(0,0,0,0.75)); */
    overflow: visible;

    .title {
      font-weight: 600;
      color: rgb(152 200 235);
    }

    .cont {
      display: flex;
      justify-content: space-around;
      gap: 0 10px;

      .slot {
        margin: 10px 0 0 0;
        font-size: 13px;
        color: rgb(255 255 255);

        .list {
          display: flex;

          img {
            max-width: 35px;
          }
        }
      }
      .type {
        margin: 10px 0 0 0;
        font-size: 13px;
        color: rgb(255 255 255);
      }
    }

    .races {
      margin: 10px 0 0 0;
      color: rgb(152 200 235);

      img {
        width: 24px;
        aspect-ratio: 1/1;
      }
    }
    
    .description {
      margin: 5px 0 0 0;
      padding: 0 10px 0 10px;
      font-size: 13px;
      color: rgb(255 255 255);
    }
    .stats {
      margin: 5px 0 5px 0;
      font-size: 13px;
      color: rgb(255 255 255);
      column-count: 2;
    }
  }
</style>
