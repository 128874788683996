export const db = {
  'ShusRangers': {
    'ShuMercs': {
      'Корпусный акрин': [
        {
          name:'Корпус "Мерценарий" I',
          slot:["Корпус"],
          races:["Малоки","Пеленги","Люди","Фэяне"],
          type:'Корпусный акрин',
          description:`Древний корпус, чья технологическая начинка была полностью переоборудована под нужды современных наёмных спецподразделений. Помимо сниженной восприимчивости к урону, корпус имеет встроенные аналитические модули, запатентованные крупнейшими наёмническими группировками, использование которых контролируется по их приватным каналам.`,
          stats: {
            'Дальность захвата': ["-10","ед."],
            'Мощность захвата': ["-10","ед."],
            'Мощность сканера': ["-6","ед."],
            'Навык лидерства': ["1"],
            'Навык манёвренности': ["1"],
            'Навык обаяния': ["1"],
            'Навык техники': ["1"],
            'Навык торговли': ["1"],
            'Навык точности': ["1"],
            'Скорость захвата': ["-2","ед."]
          },
        },
        {
          name:'Корпус "Мерценарий" II',
          slot:["Корпус"],
          races:["Малоки","Пеленги","Люди","Фэяне"],
          type:'Корпусный акрин',
          description:`Древний корпус, чья технологическая начинка была полностью переоборудована под нужды современных наёмных спецподразделений. Помимо сниженной восприимчивости к урону, корпус имеет встроенные аналитические модули, запатентованные крупнейшими наёмническими группировками, использование которых контролируется по их приватным каналам.`,
          stats: {
            'Дальность захвата': ["-10","ед."],
            'Мощность захвата': ["-10","ед."],
            'Мощность сканера': ["-6","ед."],
            'Навык лидерства': ["1"],
            'Навык манёвренности': ["1"],
            'Навык обаяния': ["1"],
            'Навык техники': ["1"],
            'Навык торговли': ["1"],
            'Навык точности': ["1"],
            'Скорость захвата': ["-2","ед."]
          },
        },
        {
          name:'Корпус "Мерценарий" III',
          slot:["Корпус"],
          races:["Малоки","Пеленги","Люди","Фэяне"],
          type:'Корпусный акрин',
          description:`Древний корпус, чья технологическая начинка была полностью переоборудована под нужды современных наёмных спецподразделений. Помимо сниженной восприимчивости к урону, корпус имеет встроенные аналитические модули, запатентованные крупнейшими наёмническими группировками, использование которых контролируется по их приватным каналам.`,
          stats: {
            'Дальность захвата': ["-10","ед."],
            'Мощность захвата': ["-10","ед."],
            'Мощность сканера': ["-6","ед."],
            'Навык лидерства': ["1"],
            'Навык манёвренности': ["1"],
            'Навык обаяния': ["1"],
            'Навык техники': ["1"],
            'Навык торговли': ["1"],
            'Навык точности': ["1"],
            'Скорость захвата': ["-2","ед."]
          },
        },
        {
          name:'Корпус "Мерценарий" IV',
          slot:["Корпус"],
          races:["Малоки","Пеленги","Люди","Фэяне"],
          type:'Корпусный акрин',
          description:`Древний корпус, чья технологическая начинка была полностью переоборудована под нужды современных наёмных спецподразделений. Помимо сниженной восприимчивости к урону, корпус имеет встроенные аналитические модули, запатентованные крупнейшими наёмническими группировками, использование которых контролируется по их приватным каналам.`,
          stats: {
            'Дальность захвата': ["-15","ед."],
            'Мощность захвата': ["-15","ед."],
            'Мощность сканера': ["-7","ед."],
            'Навык лидерства': ["1"],
            'Навык манёвренности': ["1"],
            'Навык обаяния': ["1"],
            'Навык техники': ["1"],
            'Навык торговли': ["1"],
            'Навык точности': ["1"],
            'Скорость захвата': ["-3","ед."]
          },
        },
      ],
    }
  }
}
