<template>
<div class='bottomPanel'>
  <div class='statsList'>
    <div class='title'>Статы</div>
    <div class='horisont'>
      <div class='ver'>
        <tStat name='Размер корпуса' v-model:statsV='stats' />
        <tStat name='Броня корпуса' v-model:statsV='stats' />

        <tStat name='Акриновые бонусы' v-model:statsV='stats' />
        <tStat name='Акриновые штрафы' v-model:statsV='stats' />

        <tStat name='Эффективность дроида' v-model:statsV='stats' />
        <tStat name='Генератор поля' v-model:statsV='stats' />
      </div>

      <div class='ver'>
        <tStat name='Скорость двигателя' v-model:statsV='stats' />
        <tStat name='Дальность прыжка' v-model:statsV='stats' />
        <tStat name='Объём бака' v-model:statsV='stats' />
        <tStat name='Размер' v-model:statsV='stats' />
        <tStat name='Стоимость' v-model:statsV='stats' />
        <tStat name='Надёжность' v-model:statsV='stats' />
      </div>

      <div class='ver'>
        <tStat name='Навык лидерства' v-model:statsV='stats' />
        <tStat name='Навык манёвренности' v-model:statsV='stats' />
        <tStat name='Навык точности' v-model:statsV='stats' />
        <tStat name='Навык техники' v-model:statsV='stats' />
        <tStat name='Навык торговли' v-model:statsV='stats' />
        <tStat name='Навык обаяния' v-model:statsV='stats' />
      </div>
    </div>

    <div class='horisont'>
      <div class='ver'>
        <tStat name='Дальность радара' v-model:statsV='stats' />

        <tStat name='Дальность захвата' v-model:statsV='stats' />
        <tStat name='Мощность захвата' v-model:statsV='stats' />

        <tStat name='Мощность сканера' v-model:statsV='stats' />
      </div>

      <!-- <div class='ver'>
      <tStat name='Скорость двигателя' />
      <tStat name='Дальность прыжка' />
      <tStat name='Объём бака' />
      </div> -->

      <div class='ver'>
      <tStat name='Дальность стрельбы' v-model:statsV='stats' />
      <tStat name='Сила ракетного оружия' v-model:statsV='stats' />
      <tStat name='Сила осколочного оружия' v-model:statsV='stats' />
      <tStat name='Сила энергетического оружия' v-model:statsV='stats' />
      </div>

      <!-- <div class='ver'>
      <tStat name='Эффективность дроида' />
      <tStat name='Генератор поля' />
      </div> -->
    </div>
    <!-- <template v-for='(i, key) in stats'> -->
      <!-- <tStat :name='key' /> -->
        <!-- <div class='stat'>{{key}}
          <div class='cont'><div class='val all'>{{stats[key].all||0}}</div>
            <div class='hidden'>
              <template v-for='(e, k) in stats[key]'>
                <template v-if="k !== 'all'">
                  <div :class="`val ${k.replace(/ (.)/mg, (d, t) => t.toUpperCase())}`" :title='k'>
                  {{k === 'all' && e||0 || e[0]||0}}
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div> -->
    <!-- </template> -->
  </div>
  <!-- <div class='actions'>
    <button @click='urlGen()' title='Получить ссылку на билд'>GET</button>
  </div> -->
</div>
</template>

<script>
import tStat from './stats/stat.vue';
export default {
name: 'bottomPanel',
  components: {
    tStat
  },
  props: {
    savedV: Object,
    statsV: Object
  },
  computed: {
    stats: {
      get() {
        return this.statsV
      },
      set(value) {
        this.$emit('updatev-model:statsVV', value)
      }
    },
    saved: {
      get() {
        return this.savedV
      },
      set(value) {
        this.$emit('update:savedV', value)
      }
    }
  },
  methods:{
    change(e){
      if(!e.selectedIndex > 0) return;
      const s = {
        index: e.selectedIndex,
        value: e.options[e.selectedIndex].value,
        group: e.options[e.selectedIndex].parentNode.label
      }
      console.log(s);
      console.log(this.db[s.group][s.index]);

      this.setter({file:this.db[s.group][s.index]});
    },
    setter(o){
      console.log('[Setter]', o.file);

      this.clear();

      o.file.slot.forEach(e => {
        console.log(e)

        for(let s in o.file.stats){
          this.stats[s][e] = o.file.stats[s];
        }

        console.log(this.stats);
      })
    },
    read(){
      const parsed = new URL(document.location.href).searchParams;
      console.log(parsed);

      parsed.forEach((e, key) => {
          const s = `${key}: ${e.split(',')}`;
          console.log(s);
      });
    },
    load(){
      console.log('[Load]');
      document.querySelector(`.select[name=Корпус] .group[name='ShusRangers/ShuMercs'] .item[value='Корпус "Мерценарий" II']`).click();
    },
    async urlGen(){
      console.log('SAVED', this.saved);
      const url = [];
      for(let i in this.saved){
        console.log(i);
        url.push(this.saved[i]);
      }
      async function write(text) {
        try {
          await navigator.clipboard.writeText(text);
          console.log('[UrlGen] Saved to clipboard!');
        } catch (error) {
          console.error(error.message);
        }
      }
      write(encodeURI(`${document.location.href.replace(/(.+)(\/)$/gm, '$1')}?saved=true&${url.join('&')}`));
      //console.log('URL', '?'+'saved=true&'+url.join('&'));
    }
  },
  setup(){
  }
}
</script>

<style>
.bottomPanel,.statsList,.horisont {
    display: flex
}
.bottomPanel {
  display: flex;
  gap: 0 10px;
  position: absolute;
  bottom: 0;
  float: inline-end;
  box-shadow: 0 0 3px 1px rgb(0 0 0);

  .statsList {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    padding: 5px;
    background-color: rgb(0 0 0);
  
    .title {
      color: rgb(255 255 255);
    }
  
    .horisont {
      display: flex;
      flex-direction: row;
      gap: 0 10px;
      border-bottom: 1px solid rgb(85 85 55);
  
      .ver {
        display: flex;
        flex-direction: column;
        gap: 3px 0;
        padding: 5px;
      }
    }

    .stat {
      display: var(--f);
      flex-direction: row;
      justify-content: right;
      gap: 0 5px;
    
      .cont {
        display: var(--f);
        flex-direction: row;
        gap: 0 3px;
        position: relative;
        cursor: pointer;
    
        &:hover {
          .hidden {
            display: var(--f);
          }
        }
        &:focus {
          .hidden {
            display: var(--f);
          }
        }
    
        .hidden {
          display: none;
          flex-direction: row;
          gap: 0 3px;
          left: 100%;
          margin-left: 5px;
          padding: 1px 5px 0 5px;
          position: absolute;
          border-radius: 2px;
          background-color: rgb(0 0 0);
        }
        .hidden:has(*) {
          box-shadow: 0 0 2px 1px rgb(255 255 255);
        }
      }
    
      .name {
        font-size: 12px;
        color: rgb(255 255 255);
      }
    
      .val {
        font-size: 12px;
        color: rgb(255 255 255);
        font-weight: 600;
      }
    
      .Корпус {
        color: var(--Корпус);
      }
      .Двигатель {
        color: var(--Двигатель);
      }
      .ТопливныйБак {
        color: var(--ТопливныйБак);
      }
      .Захват {
        color: var(--Захват);
      }
      .РемонтныйДроид {
        color: var(--РемонтныйДроид);
      }
      .ГенераторЗащитногоПоля {
        color: var(--ГенераторЗащитногоПоля);
      }
    }
  }
}
</style>
