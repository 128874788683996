<template>
<div class='slot'>
  <div class='leftPanel'>
    <div class='yo'>
      <div class='title Корпус'>Корпус</div>
        <div class='container'>
          <UsSelect name='Корпус' :items='Hull' v-model:statsV='stats' v-model:barV='bar' v-model:savedV='saved' />
          <div class='show'><div class='title'>ММ</div>
            <div class='wix'>
              <UsSelect name='Корпус ММ' :mm='true' :items='HullMM' v-model:statsV='stats' v-model:barV='bar' v-model:savedV='saved' />
            </div>
          </div>
        </div>
    </div>
    <div class='yo'>
      <div class='title Двигатель'>Двигатель</div>
        <div class='container'>
          <UsSelect name='Двигатель' :items='Engine' v-model:statsV='stats' v-model:barV='bar' v-model:savedV='saved' />
          <div class='show'><div class='title'>ММ</div>
            <div class='wix'>
              <UsSelect name='Двигатель ММ' :mm='true' :items='EngineMM' v-model:statsV='stats' v-model:barV='bar' v-model:savedV='saved' />
            </div>
          </div>
        </div>
      </div>
    <div class='yo'>
      <div class='title ТопливныйБак'>Топливный бак</div>
        <div class='container'>
          <UsSelect name='Топливный бак' :items='FuelTank' v-model:statsV='stats' v-model:barV='bar' v-model:savedV='saved' />
          <div class='show'><div class='title'>ММ</div>
            <div class='wix'>
              <UsSelect name='Топливный бак ММ' :mm='true' :items='FuelTankMM' v-model:statsV='stats' v-model:barV='bar' v-model:savedV='saved' />
            </div>
          </div>
        </div>
      </div>
    <div class='yo'>
      <div class='title Захват'>Захват</div>
        <div class='container'>
          <UsSelect name='Захват' :items='CargoHook' v-model:statsV='stats' v-model:barV='bar' v-model:savedV='saved' />
          <div class='show'><div class='title'>ММ</div>
            <div class='wix'>
              <UsSelect name='Захват ММ' :mm='true' :items='CargoHookMM' v-model:statsV='stats' v-model:barV='bar' v-model:savedV='saved' />
            </div>
          </div>
        </div>
      </div>
    <div class='yo'>
      <div class='title РемонтныйДроид'>Ремонтный дроид</div>
        <div class='container'>
          <UsSelect name='Ремонтный дроид' :items='RepairRobot' v-model:statsV='stats' v-model:barV='bar' v-model:savedV='saved' />
          <div class='show'><div class='title'>ММ</div>
            <div class='wix'>
              <UsSelect name='Ремонтный дроид ММ' :mm='true' :items='RepairRobotMM' v-model:statsV='stats' v-model:barV='bar' v-model:savedV='saved' />
            </div>
          </div>
        </div>
      </div>
    <div class='yo'>
      <div class='title ГенераторЗащитногоПоля'>Генератор защитного поля</div>
        <div class='container'>
          <UsSelect name='Генератор защитного поля' :items='DefGenerator' v-model:statsV='stats' v-model:barV='bar' v-model:savedV='saved' />
          <div class='show'><div class='title'>ММ</div>
            <div class='wix'>
              <UsSelect name='Генератор защитного поля ММ' :mm='true' :items='DefGeneratorMM' v-model:statsV='stats' v-model:barV='bar' v-model:savedV='saved' />
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class='actions'>
    <button @click='urlGen()' title='Получить ссылку на билд'>GET</button>
    <button @click='clearAll()'>CLEAR</button>
  </div>
</div>
<rightPanel :data='bar' />
</template>

<script>
import {db as ShuMercs} from '../../../db/ShusRangers/ShuMercs/mjs.js';
import {db as ShuMercsT} from '../../../db/ShusRangers/ShuMercs/test.js';
import {db as EvoBalance} from '../../../db/Evolution/EvoBalance/mjs.js';
import {stats} from '../../../db/stats.js';
import rightPanel from './rightPanel.vue';
import UsSelect from '../select/mjs.vue';
export default {
name: 'leftPanel',
components: {
  rightPanel,
  //picker,
  UsSelect
},
props: {
  statsV: Object,
  savedV: Object,
  msg: String
},
computed: {
  stats: {
    get() {
      return this.statsV
    },
    set(value) {
      this.$emit('update:statsV', value)
    }
  },
  saved: {
    get() {
      return this.savedV
    },
    set(value) {
      this.$emit('update:savedV', value)
    }
  }
},
data(){
  return {
    Hull: {
      'ShusRangers∶ShuMercs': {
        name: 'ShusRangers∶ShuMercs',
        db: ShuMercs['ShusRangers']['ShuMercs']['Корпусный акрин'],
        //...EvoBalance['Evolution']['EvoBalance']
      },
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['Корпусный акрин']
      }
    },
    Engine: {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['Акрин оборудования'].filter(e => e.slot.includes('Двигатель'))
      }
    },
    FuelTank: {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['Акрин оборудования'].filter(e => e.slot.includes('Топливный бак'))
      }
    },
    Radar: {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['Акрин оборудования'].filter(e => e.slot.includes('Радар'))
      }
    },
    Scaner: {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['Акрин оборудования'].filter(e => e.slot.includes('Сканер'))
      }
    },
    RepairRobot: {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['Акрин оборудования'].filter(e => e.slot.includes('Ремонтный дроид'))
      }
    },
    CargoHook: {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['Акрин оборудования'].filter(e => e.slot.includes('Захват'))
      }
    },
    DefGenerator: {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['Акрин оборудования'].filter(e => e.slot.includes('Генератор защитного поля'))
      }
    },

    'HullMM': {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['ММ'].filter(e => e.slot.includes('Корпус'))
      }
    },
    'EngineMM': {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['ММ'].filter(e => e.slot.includes('Двигатель'))
      }
    },
    'FuelTankMM': {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['ММ'].filter(e => e.slot.includes('Топливный бак'))
      }
    },
    'RadarMM': {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['ММ'].filter(e => e.slot.includes('Радар'))
      }
    },
    'ScanerMM': {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['ММ'].filter(e => e.slot.includes('Сканер'))
      }
    },
    'RepairRobotMM': {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['ММ'].filter(e => e.slot.includes('Ремонтный дроид'))
      }
    },
    'CargoHookMM': {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['ММ'].filter(e => e.slot.includes('Захват'))
      }
    },
    'DefGeneratorMM': {
      'Evolution∶EvoBalance': {
        name: 'Evolution∶EvoBalance',
        db: EvoBalance['Evolution']['EvoBalance']['ММ'].filter(e => e.slot.includes('Генератор защитного поля'))
      }
    },
    SMt: ShuMercsT['ShusRangers']['ShuMercs'],
    def: stats,
    //stats: stats,
    bar: {},
    //saved: {}
  }
},
methods:{
  change(e, file){
    // if(!e.selectedIndex > 0) return;
    const s = {
      name: e.name,
      index: e.selectedIndex,
      value: e.options[e.selectedIndex]?.value,
      group: e.options[e.selectedIndex]?.parentNode?.label
    }
    //console.log(s);
    //console.log(this.db[s.group][s.index]);

    s.group ? this.setter({file:file[s.group][s.index]}) : this.setter({name:s.name});
  },
  setter(o){
    //console.log('[Setter]', o.file);

    if(!o.file) for(let s in stats){
      this.stats[s][o.name] = '';
    }else
    o.file.slot.forEach(e => {
      console.log(e)

      for(let s in o.file.stats){
        this.stats[s][e] = o.file.stats[s];
      }
    })

    //console.log('[stats]', this.stats);
  },
  clear(){
    console.log('[Clear]');
    Object.assign(this.stats, this.def);
  },
  clearAll(){
    for(let i = 0, arr = document.querySelectorAll(`.leftPanel .select .item[value='-']`), len = arr.length; i < len; i++){
      arr[i].click();
    }
  },
  async urlGen(){
    console.log('SAVED', this.saved);
    const url = [];
    for(let i in this.saved){
      //console.log(i);
      url.push(this.saved[i]);
    }
    async function write(text) {
      try {
        await navigator.clipboard.writeText(text);
        console.log('[UrlGen] Saved to clipboard!');
      } catch (error) {
        console.error(error.message);
      }
    }
    write(encodeURI(`${document.location.href.replace(/(.+)(\/)$/gm, '$1')}?saved=true&${url.join('&')}`));
    //console.log('URL', '?'+'saved=true&'+url.join('&'));
  }
}
}
</script>

<style>
.slot {
display: flex;
flex-direction: column;
gap: 10px 0;

.actions {
  display: flex;
  flex-direction: row;
  gap: 0 5px;
  padding: 5px;
  background-color: rgb(0 0 0);
  box-shadow: 0 0 3px 1px rgb(0 0 0);

  button {
    aspect-ratio: 1/1;
    color: rgb(255 255 255);
    border-radius: 2px;
    border: 1px solid rgb(255 255 255);
    background-color: rgb(63 0 61);
    box-shadow: 1px 1px 3px 0px rgb(136 200 217);

    &:hover {
      filter: sepia(1);
      cursor: pointer;
    }
  }
}
}
.leftPanel {
  display: flex;
  flex-direction: column;
  gap: 5px 0;
  padding: 7px;
  box-shadow: 0 0 3px 1px rgb(0 0 0);

  .select {
    background-color: rgb(255 255 255);
    box-shadow: 1px 1px 3px 0px rgb(129 129 129);
  }
}
label {
display: var(--f);
flex-direction: column;
}

.yo {
display: var(--f1);
flex-direction: column;
gap: 0 5px;

.container {
  display: var(--f);
  flex-direction: row;
  gap: 0 5px;
}

>.title {
  display: flex;
  justify-content: center;
  gap: 0 5px;
  font-weight: 600;

  &::before {
    display: block;
    content: '';
    aspect-ratio: 1/1;
    height: 10px;
    border-radius: 50%;
  }

  &.Корпус {

    &::before {
      background-color: var(--Корпус);
    }
  }
  &.Двигатель {

    &::before {
      background-color: var(--Двигатель);
    }
  }
  &.ТопливныйБак {

    &::before {
      background-color: var(--Корпус);
    }
  }
  &.Захват {

    &::before {
      background-color: var(--Захват);
    }
  }
  &.РемонтныйДроид {

    &::before {
      background-color: var(--РемонтныйДроид);
    }
  }
  &.ГенераторЗащитногоПоля {

    &::before {
      background-color: var(--ГенераторЗащитногоПоля);
    }
  }
}

.show {
  display: var(--f);
  flex-direction: row;
  gap: 0 0;
  position: relative;

  .title {
    padding: 0 3px 0 3px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid rgb(0 0 0);
    box-shadow: 1px 1px 3px 0px rgb(129 129 129);
  }

  .wix {
    display: none;
    position: absolute;
    left: 100%;

    label {
      padding: 0 0 0 10px;
  
      .select {
        position: relative;
        flex-direction: column;
        z-index: 1;
      }
    }
  }

  &:hover {
    .wix {
      display: var(--f);
      /*background-color: rgb(255 255 255);*/
    }
  }
}
}
</style>
