<template>
<div class='group' :name='name'>
  <div class='title'>{{name}}</div>
  <template v-for='(item, ind) in items' :key='item.name'>
    <Uitem :label='item.name' :name='type' :value='item.name' :index='ind' @getItem='sendGroup($event, name)' />
  </template>
  <!-- <list :group='list' /> -->
</div>
</template>

<script>
import Uitem from './u-item.vue';
export default {
  name: 's-group',
  components: {
    Uitem
  },
  props: {
    name: String,
    type: String,
    items: Object
  },
  methods: {
    onclick(e){
      e.preventDefault();
    },
    sendGroup(e, item){
      //console.log('[SEND group]')
      e.data.push({group:item});
      this.$emit('getPath', e);
      //console.log('yoxi', item)
    }
  }
}
</script>